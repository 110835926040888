import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "components/UserContext";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import execute from "hooks/execute";
import { protectedResources } from "config/msalConfig";
import { Menu } from "antd";
import {
  FaChartLine,
  FaComments,
  FaDatabase,
  FaTableCellsLarge,
  FaUsers,
} from "react-icons/fa6";
import * as fa6 from "react-icons/fa6";
import { ProduceJWT } from "jose";

const generalItems = [
  {
    key: "general-group",
    label: "General",
    type: "group",
    children: [
      {
        key: "faq",
        label: "FAQ",
        icon: <FaComments alt="FAQ" />,
      },
    ],
  },
];

const defaultItems = [
  {
    key: "dashboard",
    label: "Dashboard",
    icon: <FaTableCellsLarge alt="Dashboard" />,
  },
];

function Sidebar() {
  const navigate = useNavigate();
  const { userDetails } = useUser();
  const [menuItems, setMenuItems] = useState([
    ...defaultItems,
    ...generalItems,
  ]);
  const [adminMenus, setAdminMenus] = useState(null);
  const [selectedMenuKey, setSelectedMenuKey] = useState("dashboard");
  const [openMenuKeys, setOpenMenuKeys] = useState([]);

  const [reports, setReports] = useState([]);
  const location = useLocation();
  const { hash, pathname, search } = location;

  const fetchReports = () => {
    if (userDetails && userDetails.IsOrgUser === false) {
      //console.log("while fetching get-all-reports", userDetails);
      execute(
        "POST",
        `${protectedResources.api.baseEndPoint}/pbi/get-all-reports`
      )
        .then((response) => {
          //console.log('Fetched all reports:', response); // Debug log
          var fetchedReports = response.data
            .filter((r) => r.embedToken)
            .map((r) => ({
              ...r,
              module: r.name.split("-")[0]?.trim(),
              name: r.name.split("-")[1]?.trim(),
            }));
          //console.log('reports:', fetchedReports); // Debug log
          setReports(fetchedReports);
        })
        .catch((error) => console.error("Failed to fetch all reports:", error));
    }
  };

  const loadAdminMenus = () => {
    var adminMenus = [];
    if (userDetails?.IsAdmin) {
      adminMenus = {
        key: "admin-group",
        label: "Admin",
        type: "group",
        children: [
          {
            label: "User Management",
            key: "UM",
            icon: <FaUsers alt="User Management" />,
          },
          {
            label: "Data Administration",
            key: "DA",
            icon: <FaDatabase alt="Data Administration" />,
          },
        ],
      };
      setAdminMenus(adminMenus);
      setMenuItems([...defaultItems, adminMenus, ...generalItems]);
    }
  };

  const setCurrentMenuActive = () => {
    if (pathname.startsWith("/report/")) {
      const parts = pathname.split("/");
      //console.log('setCurrentMenuActive:', parts);
      if (parts.length >= 3) {
        const moduleShortName = parts[2];
        const reportId = parts[3];
        //console.log('setCurrentMenuActive:', moduleShortName, reportId);
        setOpenMenuKeys([moduleShortName]);
        setSelectedMenuKey(reportId);
        return;
      }
    }
    switch (pathname) {
      case "/dashboard":
        setSelectedMenuKey("dashboard");
        break;
      case "/userManage":
        setSelectedMenuKey("UM");
        break;
      case "/data-administration":
        setSelectedMenuKey("DA");
        break;
      case "/faq":
        setSelectedMenuKey("faq");
        break;
      default:
        //console.log('default:', pathname, location);
        break;
    }
  };

  useEffect(() => {
    setMenuItems([...defaultItems, ...generalItems]);
    loadAdminMenus();
    fetchReports();
    setCurrentMenuActive();
  }, [userDetails]);

  const getIcon = (Icon) => <Icon />;

  useEffect(() => {
    if (reports.length > 0 && userDetails?.Modules) {
      const moduleMenus = {
        key: "module-group",
        label: "Modules",
        type: "group",
        children: userDetails.Modules.map((module) => {
          const moduleReports = reports.filter(
            (r) => r.module === module.ShortName
          );
          //console.log("moduleReports:", module.ShortName, moduleReports, reports);
          if (moduleReports.length === 0) {
            return null;
          }
          return {
            label: module.ModuleName,
            key: module.ShortName,
            // icon: <module.Icon alt={module.ModuleName} /> ,
            icon: getIcon(fa6[module.Icon]),
            children: moduleReports.map((report) => {
              return {
                label: report.name,
                key: report.id,
                icon: <FaChartLine alt={report.name} />,
                onClick: () =>
                  handleReportClick(
                    report.id,
                    report.embedUrl,
                    report.embedToken,
                    report.isEmbedToken,
                    report.module
                  ),
              };
            }),
          };
        }).filter((module) => module !== null),
      };
      setMenuItems([...defaultItems, adminMenus, moduleMenus, ...generalItems]);
    }
  }, [userDetails, reports]);

  const handleReportClick = (
    reportId,
    embedUrl,
    accessToken,
    isEmbedToken,
    moduleShortName
  ) => {
    //console.log('before calling report', reportId, embedUrl, accessToken, isEmbedToken);
    setSelectedMenuKey(reportId);
    navigate(`/report/${moduleShortName}/${reportId}`, {
      state: { embedUrl, accessToken, isEmbedToken },
    });
  };

  const onMenuItemClicked = (e) => {
    //console.log('menu item clicked:', e);
    switch (e.key) {
      case "dashboard":
        setSelectedMenuKey("dashboard");
        navigate("/");
        break;
      case "UM":
        setSelectedMenuKey("UM");
        navigate("/userManage");
        break;
      case "DA":
        setSelectedMenuKey("DA");
        navigate("/data-administration");
        break;
      case "faq":
        setSelectedMenuKey("faq");
        navigate("/faq");
        break;
      // case "CM":
      //     navigate('/inspection');
      //     break;
      // case "Citation":
      //     navigate('/citation-management');
      //     break;
      // case "Inspection":
      //     navigate('/inspection');
      //     break;
      default:
        break;
    }
  };
  return (
    <div className="flex overflow-hidden z-10 h-[calc(100vh-70px)] flex-col fixed w-[270px] bg-[#fcfcfc] border-white">
      <aside className="flex flex-col h-full">
        <header className="flex items-center text-[#002c57] text-xl gap-[9px] p-4 bg-white border-r-[1px] border-[#e5e7eb]">
          {!process.env.REACT_APP_HIDE_LOGO && (
            <img
              className="aspect-square object-cover object-center w-[53px]"
              src="https://birddata.blob.core.windows.net/b2clogin/cust-app/images/prism-logo.png"
              alt={process.env.REACT_APP_APPNAME}
            />
          )}
          {/* <h1 className="font-ubuntu mx-0 text-ellipsis whitespace-nowrap text-2xl ml-10">
            {process.env.REACT_APP_APPNAME}
          </h1> */}
         <div className="flex text-center ml-2 mx-0">
         <img
            className="w-28 object-contain"
            src="/logo.png"
            alt="logo"
          />
         </div>
        </header>

        {/* Sidebar menu with scrollbar */}
        <div className="flex-grow overflow-y-auto">
          <Menu
            items={menuItems}
            mode="inline"
            selectedKeys={[selectedMenuKey]}
            defaultSelectedKeys={["dashboard"]}
            onClick={onMenuItemClicked}
          />
        </div>
      </aside>
    </div>
  );
}

export default Sidebar;
