import React from "react";
import styled from "styled-components";


const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  record,
  onDeleteConfirmed,
}) => {
  if (!isOpen) return null;

  const handleDelete = () => {
    onDeleteConfirmed(record);
  };

  return (
    <>
      <div
        className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-10"
        onClick={onClose}
      />
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 z-20 w-4/5 max-w-[600px] border border-[#ccc] rounded-lg shadow-l">
        <h2>Confirm Deletion</h2>
        <p>
          Are you sure you want to delete this item? This action cannot be
          undone.
        </p>
        <div className="flex justify-center gap-[10px]">
          <button className="px-5 py-2.5 text-base bg-[#f60] text-white border-none rounded cursor-pointer transition-colors duration-200 hover:bg-[#e55]" type="button" onClick={handleDelete}>
            Delete
          </button>
          <button className="px-5 py-2.5 text-base bg-[#f60] text-white border-none rounded cursor-pointer transition-colors duration-200 hover:bg-[#e55]" type="button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default DeleteConfirmationModal;
