import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import styled from "styled-components";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useEffect } from "react";
import { loginRequest } from "../config/msalConfig";
import { InteractionStatus } from "@azure/msal-browser";
import Feedback from "../views/Feedback";
import { App } from "antd";

export const PageLayout = (props) => {
  // const { instance, inProgress } = useMsal();
  // let activeAccount;

  // if (instance) {
  //     activeAccount = instance.getActiveAccount();
  // }

  // useEffect(() => {
  //   if(!activeAccount && inProgress === InteractionStatus.None){
  //     instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  //   }
  // }, [inProgress, instance, activeAccount]);

  return (
    <>
      <AuthenticatedTemplate>
        <div className="flex flex-col min-h-screen gap-0 overflow-x-hidden">
          <App>
            <Header />
            <Feedback />
            <div className="flex flex-row w-full min-h-[calc(100vh-80px)] mt-[77px] flex-grow">
              <Sidebar />
              <div className="flex flex-col flex-grow  overflow-x-hidden">
                {/* Main content */}
                <div className="flex-grow overflow-y-auto ml-[300px] overflow-x-hidden">
                  {props.children}
                </div>

                {/* Footer */}
                <div className="ml-[250px] mt-auto">
                  <Footer />
                </div>
              </div>
            </div>
          </App>
        </div>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div className="flex flex-col min-h-screen gap-0 mx-20">
          <div className="flex flex-row w-full h-full gap-2 flex-grow ">
            {props.children}
          </div>
        </div>
      </UnauthenticatedTemplate>
    </>
  );
};

export default PageLayout;
