import { useEffect, useState } from "react";
import ModuleRefreshDataService from "../../services/ModuleRefreshDataService";
import ModuleRefreshWidget from "./components/ModuleRefreshWidget";
import { Col, Row } from "antd";

function DataWidgetsSection() {
    const [moduleRefreshData, setModuleRefreshData] = useState([]);
    const fetchModuleRefreshData = async () => {
      const data = await ModuleRefreshDataService.getModuleRefreshData();
      setModuleRefreshData(data);
    }
    useEffect(() => {
      fetchModuleRefreshData();
    }, []);


    return (
        <div className="mt-[100px] w-[100%] py-0 px-2">
            <Row gutter={16}>
                {moduleRefreshData.map((item, index) => (
                    <Col key={index} span={8}>
                        <ModuleRefreshWidget key={index} data={item} />
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default DataWidgetsSection;