import React, { useState, useEffect, useMemo } from "react";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useUser } from "./UserContext";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

import execute from "../hooks/execute";
import { protectedResources } from "../config/msalConfig";

function Inspection() {
  return (
    <div className="flex flex-col w-full gap-5">
      <NewCitation />
      <InspectionForm />
    </div>
  );
}

function NewCitation() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { userDetails } = useUser();

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <div>
      <header className="flex p-[10px] mt-[20px] mr-[-100px] ml-[-170px] sm:flex-wrap">
        <h1 className="m-0 text-[30px] font-ubuntu text-[#002c57]">
          New Inspection
        </h1>
        {/* <NewOrganizationButton onClick={toggleModal}>New User</NewOrganizationButton> */}
      </header>
      {/* <NewOrganizationModal isOpen={modalIsOpen} onClose={toggleModal} userEmail={userDetails.email}  /> */}
    </div>
  );
}

const SelectField = ({ label, options, defaultValue }) => (
  <div className="flex flex-col">
    <label className="text-[#767676] text-center font-medium text-[14px] font-ubuntu">
      {label}
    </label>
    <div className="flex justify-center border border-[#bbbbbc] bg-white rounded-[2px] mt-[13px] gap-[10px] px-[21px] py-[10px] sm:px-[10px]">
      <select
        className="font-ubuntu text-[16px] text-[#767676] bg-transparent border-none appearance-none w-full"
        defaultValue={defaultValue}
      >
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      <img
        className="aspect-[1] w-[24px]"
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&"
        alt=""
      />
    </div>
  </div>
);

const InputField = ({ label, placeholder }) => (
  <div className="flex flex-col max-w-full sm:max-w-full">
    <label className="text-center text-[#767676] font-medium text-sm sm:max-w-full">
      {label}
    </label>
    <input
      className="rounded-[2px] border border-[#bbbbbc] bg-white mt-[13px] text-[#bbbbbc] py-[13px] px-[21px] font-[400] text-[16px] sm:max-w-full sm:py-[13px]"
      placeholder={placeholder}
    />
  </div>
);

function InspectionForm() {
  return (
    <form className="rounded-[2px] flex flex-col p-[17px] pl-[11px] w-[120%] ml-[-160px] mt-[-20px]">
      <h2 className="text-[#002c57] tracking-[0.4px] font-[500] text-[14px] leading-[114%] font-ubuntu mt-[-1px] sm:max-w-full">
        Primary Information
      </h2>
      <section className="flex mt-[10px] gap-[20px] justify-between sm:flex-wrap">
        <SelectField
          label="Inspection Date"
          options={["Select"]}
          defaultValue="Select"
        />
        <SelectField
          label="Inspection Facility"
          options={["Select"]}
          defaultValue="Select"
        />
        <SelectField
          label="Program Type"
          options={["Select"]}
          defaultValue="Select"
        />
        <SelectField
          label="Audit Agency"
          options={["Select"]}
          defaultValue="Select"
        />
        <SelectField
          label="Audit Type"
          options={["Licensing"]}
          defaultValue="Licensing"
        />
      </section>

      <hr className="w-full stroke-[1px] stroke-[#767676] mt-[20px] sm:max-w-full" />
      <h2 className="text-[#002c57] tracking-[0.4px] font-[500] text-[14px] leading-[114%] font-ubuntu mt-[-1px] sm:max-w-full">
        Secondary Information
      </h2>
      <section className="mt-6">
        <label className="text-[#767676] text-center font-medium text-[14px] sm:max-w-full">
          Add Citation
        </label>
        <div className="flex justify-center border border-[#bbbbbc] bg-white rounded-[2px] mt-[13px] gap-[10px] text-[16px] text-[#bbbbbc] font-normal px-[21px] py-[10px] sm:flex-wrap sm:px-[10px]">
          <span>Select citations</span>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&"
            alt=""
          />
        </div>
      </section>
      <div className="flex items-start border border-[#f16622] bg-white rounded-[16px] mt-[20px] gap-[3px] text-[#1c1c1c] font-normal tracking-[0.4px] px-[11px] py-[4px]">
        <span>Customer :</span>
        <span>Giacomo Guilizzoni</span>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/993d2b8380103033a2a7d57702d997cd5669993a791f8a2c99fc7ed755ba87dd?apiKey=1607f544a0344092815fcb145a04f0bb&"
          alt=""
        />
      </div>
      <p className="text-[#515151] mt-[20px] font-normal text-[16px] max-w-full">
        Input the individual who's record is being inspected, if applicable
      </p>
      <hr className="w-full stroke-[1px] stroke-[#767676] mt-[20px] sm:max-w-full" />
      <h2 className="text-[#002c57] tracking-[0.4px] font-medium text-[14px] leading-[114%] font-ubuntu mt-[-1px] sm:max-w-full">
        Additional Information
      </h2>
      <section className="mt-6">
        <label className="text-[#767676] text-center font-medium text-[14px] font-ubuntu max-w-full">
          Comments/Follow-up Comments
        </label>
        <textarea
          className="rounded-[2px] border font-ubuntu border-[#bbbbbc] bg-[#fff] mt-[13px] text-[#bbbbbc] py-[19px] px-[21px] font-normal text-[16px] w-full max-w-full"
          placeholder="Type in"
        />
      </section>
      <div className="self-start flex mt-[58px] gap-[13px] text-[20px] font-medium md:mt-[40px]">
        <button className="font-ubuntu sans-serif justify-center rounded-full px-[18px] py-[10px] md:px-[10px] border-[1px] border-[#f16622] bg-[#fff] text-[#f16622]">
          Cancel
        </button>
        <button className="font-ubuntu sans-serif justify-center rounded-full px-[18px] py-[10px] md:px-[10px] bg-[#f16622] text-[#fff] border-none">
          Add
        </button>
      </div>
    </form>
  );
}
export default Inspection;
