import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import styled from 'styled-components';

function Report(props) {
    const location = useLocation();
    const { embedUrl, accessToken, isEmbedToken } = location.state || {};
    const { reportId } = useParams();

    useEffect(() => {
        if (!reportId || !embedUrl || !accessToken || isEmbedToken === undefined) {
            console.error('Missing report details');
        }
        console.log(`Report details: ${reportId}, ${embedUrl}, ${accessToken}, ${isEmbedToken}`);
    }, [reportId, embedUrl, accessToken, isEmbedToken]);

    return (
        <div className='flex flex-col w-full gap-5'>
            <div className='w-full mt-[28px] flex justify-center items-end'>
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report',
                        id: reportId,
                        embedUrl: embedUrl,
                        accessToken: accessToken,
                        tokenType: isEmbedToken === true ? models.TokenType.Embed : models.TokenType.Aad,
                        settings: {
                            panes: {
                                filters: {
                                    expanded: true,
                                    visible: true
                                }
                            },
                            background: models.BackgroundType.Transparent,
                        }
                    }}
                    eventHandlers={new Map([
                        ['loaded', function () { console.log('Report loaded'); }],
                        ['rendered', function () { console.log('Report rendered'); }],
                        ['error', function (event) { console.log('Report Error:', event.detail); }],
                    ])}
                    cssClassName="w-full h-[80vh] rounded-none"
                    getEmbeddedComponent={(embeddedReport) => {
                        console.log('Embedded report object:', embeddedReport);
                    }}
                />
            </div>
        </div>

    );
}




export default Report;

