import React, { useState, useEffect } from "react";
import styled from "styled-components";

import EditUserModal from "./EditUserModel";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { useUser } from "./UserContext";
import execute from "../hooks/execute";
import { protectedResources } from "../config/msalConfig";
import { FaFilePen, FaEnvelope, FaTrash } from "react-icons/fa6";
import { App, message } from "antd";
import UserManagementTable from "views/UserManagement/UserManagementTable";

function AppLayoutUser() {
  const { userDetails } = useUser();
  const [searchResults, setSearchResults] = useState(null);
  const [refreshData, setRefreshData] = useState(false); // New state to trigger data refresh
  const [custDomain, setCustDomain] = useState("");

  useEffect(() => {
    if (userDetails && userDetails.email) {
      console.log("User Email:", userDetails.email);

      Promise.all([
        execute(
          "GET",
          `${protectedResources.api.baseEndPoint}/customers/ByEmail/${userDetails.email}`
        ),
      ])
        .then((responses) => {
          const customerData = responses[0].data;
          setCustDomain(customerData.Domain);
          console.log("Customer Data:", customerData);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [userDetails]);

  const handleUserAdded = () => {
    setRefreshData(!refreshData); // Toggle refreshData to trigger data refetch
  };

  return (
    <div className="flex flex-col w-[100%] gap-5">
      <OrganizationsPage
        custDomain={custDomain}
        onUserAdded={handleUserAdded}
      />
      {/* <SearchParameterForm setSearchResults={setSearchResults} /> */}
      {/* <Data custDomain={custDomain} searchResults={searchResults} refreshData={refreshData} setRefreshData={setRefreshData} /> */}
      <UserManagementTable
        userDetails={userDetails}
        custDomain={custDomain}
        Modal={NewOrganizationModal}
      />
    </div>
  );
}

const ModalForm = styled.form`
  .error {
    border: 1px solid #f00;
  }
  .success {
    border: 1px solid #0f0;
  }
`;

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 0 0 0 5px;
`;

const FormLabel = styled.label`
  margin-top: 10px;
  font-weight: bold;
  .error {
    border: 1px solid #f00;
  }
`;

const NewOrganizationModal = ({
  custDomain,
  isOpen,
  onClose,
  userEmail,
  onUserAdded,
}) => {
  const { userDetails } = useUser();

  const [organizationName, setOrganizationName] = useState("");
  const [address, setAddress] = useState("");
  const [domain, setDomain] = useState("");
  const [adminEmail, setAdminEmail] = useState("@" + custDomain);
  const [emailError, setEmailError] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({
    Customer_ID: "",
    CustomerName: "",
  });
  const [loading, setLoading] = useState(false);
  const [modules, setModules] = useState([]);
  const [modulesMap, setModulesMap] = useState({});
  const [modulesAssigned, setModulesAssigned] = useState({});
  const [selectedRoles, setSelectedRoles] = useState({
    "East Area 1": false,
    "South Area 1": false,
    "South Area 2": false,
    "South Area 3": false,
    "East Area 2": false,
  });
  const [isOrgUser, setIsOrgUser] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const [role, setRole] = useState("Regular User"); // New state for role dropdown
  const [customerDomain, setCustomerDomain] = useState(""); // New state for customer domain

  const resetForm = () => {
    setOrganizationName("");
    setAddress("");
    setDomain("");
    setAdminEmail("");
    setCustomers([]);
    setSelectedCustomer({ Customer_ID: "", CustomerName: "" });
    setLoading(false);
    setModules([]);
    setModulesMap({});
    setModulesAssigned({});
    setSelectedRoles({
      "East Area 1": false,
      "South Area 1": false,
      "South Area 2": false,
      "South Area 3": false,
      "East Area 2": false,
    });
    setIsOrgUser(false);
    setOrgId(null);
    setRole("Regular User");
  };

  useEffect(() => {
    if (isOpen && custDomain) {
      setCustomerDomain(custDomain);
      console.log("custDomain:", custDomain);
      setAdminEmail("@" + custDomain);
    }
  }, [isOpen, custDomain]);

  useEffect(() => {
    if (isOpen) {
      resetForm();

      execute(
        "GET",
        `${protectedResources.api.baseEndPoint}/organizations/ByEmail/${userEmail}`
      )
        .then((response) => {
          const { success, data } = response;
          console.log("Organization details:", response);
          setIsOrgUser(success);
          setOrgId(data.Org_ID);

          if (success && data.Org_ID) {
            fetchCustomers(data.Org_ID);
          }
        })
        .catch((error) => console.error("Error fetching user details:", error));

      fetchModuleDetails(userEmail);
    }
  }, [userEmail, isOpen]);

  const fetchModuleDetails = async (userEmail) => {
    try {
      const modResponse = await execute(
        "GET",
        `${protectedResources.api.baseEndPoint}/customerUsers/ByEmail/${userEmail}/organization`
      );
      console.log("modResponse:", modResponse.data); // Log response for debugging

      let fetchedModules = modResponse.data.Modules;

      // If fetchedModules is a string, split it into an array
      if (typeof fetchedModules === "string") {
        fetchedModules = fetchedModules
          .split(",")
          .map((module) => module.trim());
      }

      // Ensure fetchedModules is always an array
      if (!Array.isArray(fetchedModules)) {
        fetchedModules = [];
      }
      console.log("fetchedModules:", fetchedModules);

      const modulesResponse = await execute(
        "GET",
        `${protectedResources.api.baseEndPoint}/modules`
      );
      console.log("modulesResponse:", modulesResponse); // Log response for debugging
      const allModules = Array.isArray(modulesResponse.data)
        ? modulesResponse.data
        : [];
      console.log("allModules:", allModules);

      const modulesMap = allModules.reduce((acc, module) => {
        acc[module.ShortName] = module.ModuleName;
        return acc;
      }, {});
      console.log("modulesMap:", modulesMap);

      const initialAssignedState = fetchedModules.reduce((acc, ShortName) => {
        if (ShortName in modulesMap) {
          acc[ShortName] = false; // Ensure all are set to false initially
        } else {
          console.warn(`ShortName "${ShortName}" not found in modulesMap`);
        }
        return acc;
      }, {});
      console.log("initialAssignedState:", initialAssignedState);
      fetchedModules = fetchedModules.filter(
        (ShortName) => ShortName in modulesMap
      );
      setModules(fetchedModules);
      setModulesMap(modulesMap);
      setModulesAssigned(initialAssignedState);
    } catch (error) {
      console.error("Error fetching modules:", error);
    }
  };

  const fetchCustomers = (orgId) => {
    setLoading(true);
    execute("GET", `${protectedResources.api.baseEndPoint}/customers/${orgId}`)
      .then((response) => {
        const fetchedCustomers = Array.isArray(response.data)
          ? response.data
          : [];
        setCustomers(fetchedCustomers);
        if (fetchedCustomers.length > 0) {
          const firstCustomer = fetchedCustomers[0];
          setSelectedCustomer({
            Customer_ID: firstCustomer.Customer_ID,
            CustomerName: firstCustomer.CustomerName,
          });
          setCustomerDomain(firstCustomer.Domain);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching customers:", error);
        setLoading(false);
      });
  };

  const onEmailChange = async (email) => {
    setAdminEmail(email);
    await ValidateEmailDomain(email);
  };

  const checkIfUserWithEmailExists = async (email) => {
    try {
      const response = await execute(
        "GET",
        `${protectedResources.api.baseEndPoint}/customerUsers/exists/${email}`
      );
      if (response.success) {
        return response.data;
      } else {
        message.error(response.message || "Failed to check if user exists");
        return { exists: false };
      }
    } catch (error) {
      console.error("Error checking if user exists:", error);
      message.error("Error checking if user exists: " + error.message);
      return { exists: false };
    }
  };

  const ValidateEmailDomain = async (email) => {
    console.log("custEmail:", email);
    if (!email) {
      setEmailError("Email is required");
    }
    //  check if email is valid and suffix with domain
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError("Invalid email format");
    } else if (email && customerDomain && !email.endsWith(customerDomain)) {
      setEmailError(
        "Email domain should match with the customer Domain: " + customerDomain
      );
    } else {
      setEmailError("Checking if user exists...");
      var emailExists = await checkIfUserWithEmailExists(email);
      if (emailExists.exists) {
        setEmailError("A user with this email already exists");
      } else {
        setEmailError(null);
        return true;
      }
    }
    return false;
  };

  const handleModuleChange = (moduleName, event) => {
    setModulesAssigned((prevState) => ({
      ...prevState,
      [moduleName]: event.target.checked,
    }));
  };

  const handleRoleChange = (role) => {
    setSelectedRoles((prevRoles) => ({
      ...prevRoles,
      [role]: !prevRoles[role],
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log({
      organizationName,
      address,
      domain,
      adminEmail,
      userEmail,
      selectedCustomer,
      modulesAssigned,
      selectedRoles,
    });

    if (!(await ValidateEmailDomain(adminEmail))) {
      message.error("either email is invalid or already exists");
      return;
    }

    try {
      const response = await execute(
        "POST",
        `${protectedResources.api.baseEndPoint}/customerUsers`,
        {
          organizationName,
          address,
          domain: role === "Admin" ? 1 : 0,
          adminEmail,
          userEmail,
          selectedCustomer,
          modulesAssigned,
          selectedRoles,
          // role: role === 'Admin' ? 1 : 0 // Send 1 for Admin and 0 for Regular User
        }
      );

      if (response.success) {
        const data = await response.data;
        console.log("Success:", data);
        alert(`user created: ${data.Email}`);
        onClose();
        onUserAdded();
      } else {
        throw new Error(response.message || "Failed to create user");
      }
    } catch (error) {
      console.error("Error:", error);
      alert(`Error: ${error.message}`);
    }
  };

  if (!isOpen) return null;

  console.log("Modules:", modules);

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-10"
        onClick={onClose}
      />
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 z-20 w-4/5 max-w-[600px] border border-gray-300 rounded-lg shadow-md mb-24">
        <h2>New User</h2>
        <ModalForm onSubmit={handleSubmit}>
          <div className="flex flex-row flex-wrap w-full">
            <FormColumn className="left-column">
              <FormLabel>First Name</FormLabel>
              <input
                className="p-2 mt-1 border border-gray-300 rounded-sm"
                type="text"
                value={organizationName}
                onChange={(e) => setOrganizationName(e.target.value)}
                placeholder="Type in"
              />

              {isOrgUser && (
                <>
                  <FormLabel>Customer</FormLabel>
                  <select
                    className="p-[8px] mt-[5px] border-[1px] border-[#ccc] rounded-[4px]"
                    value={`${selectedCustomer.Customer_ID}|${selectedCustomer.CustomerName}`}
                    onChange={(e) => {
                      const [Customer_ID, CustomerName] =
                        e.target.value.split("|");
                      var customer = customers.find(
                        (c) => c.Customer_ID === Customer_ID * 1
                      );
                      console.log("Customer:", customer, customers);
                      setCustomerDomain(customer.Domain);
                      setSelectedCustomer({ Customer_ID, CustomerName });
                    }}
                    disabled={loading}
                  >
                    {loading ? (
                      <option>Loading...</option>
                    ) : (
                      customers.map((customer) => (
                        <option
                          key={customer.Customer_ID}
                          value={`${customer.Customer_ID}|${customer.CustomerName}`}
                        >
                          {customer.CustomerName}
                        </option>
                      ))
                    )}
                  </select>
                </>
              )}

              <FormLabel>User Email [someemail@{customerDomain}]</FormLabel>
              <input
                className={`p-2 mt-1 border border-gray-300 rounded-sm ${
                  adminEmail && (emailError ? "error" : "success")
                }`}
                type="email"
                value={adminEmail}
                onChange={async (e) => await onEmailChange(e.target.value)}
                placeholder={"@" + customerDomain}
              />
              {emailError && <span className="text-red-500">{emailError}</span>}

              <FormLabel>Modules To Assign</FormLabel>

              {modules.map((shortName) => (
                <div className="flex space-x-2 my-1" key={shortName}>
                  <input
                 
                    type="checkbox"
                    id={shortName}
                    name={shortName}
                    checked={modulesAssigned[shortName] || false}
                    onChange={(e) => handleModuleChange(shortName, e)}
                  />
                  <label htmlFor={shortName}>{modulesMap[shortName]}</label>
                </div>
              ))}
            </FormColumn>
            <FormColumn className="right-column ">
              <FormLabel>Last Name</FormLabel>
              <input
                className="p-2 mt-1 border border-gray-300 rounded-sm "
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Type in"
              />

              <FormLabel>Role</FormLabel>
              <select
                className="p-[9px] mt-1 border border-gray-300 rounded-sm "
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="Regular User">Regular User</option>
                <option value="Admin">Admin</option>
              </select>

              {/* <FormLabel>Assign Roles</FormLabel>
                {Object.keys(selectedRoles).map((role) => (
                  <div key={role}>
                    <input
                      type="checkbox"
                      id={role}
                      checked={selectedRoles[role]}
                      onChange={() => handleRoleChange(role)}
                    />
                    <label htmlFor={role}>{role}</label>
                  </div>
                ))} */}
            </FormColumn>
          </div>
          <div className="flex justify-center gap-2.5">
            <button
              className="px-5 py-2.5 text-lg bg-orange-500 text-white border-none rounded-md cursor-pointer transition-colors duration-200 hover:bg-orange-600"
              type="submit"
            >
              Submit
            </button>
            <button
              className="px-5 py-2.5 text-lg bg-orange-500 text-white border-none rounded-md cursor-pointer transition-colors duration-200 hover:bg-orange-600"
              type="button"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </ModalForm>
      </div>
    </>
  );
};

function OrganizationsPage({ custDomain, onUserAdded }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { userDetails } = useUser();

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <div>
      <header className="flex justify-between items-center gap-2.5 p-5 mr-[-100px] mt-5 w-[93%] sm:flex-wrap">
        <h1 className="m-0 text-3xl font-ubuntu text-[#002c57]">
          User Management
        </h1>
        {/* <button className="px-4 py-2.5 text-xl font-ubuntu text-white bg-[#f16622] border-none rounded cursor-pointer" onClick={toggleModal}>New User</button> */}
      </header>
      {/* <NewOrganizationModal
        custDomain={custDomain}
        isOpen={modalIsOpen}
        onClose={toggleModal}
        userEmail={userDetails.email}
        onUserAdded={onUserAdded} // Pass the onUserAdded prop to the modal
      /> */}
    </div>
  );
}

function SearchParameterForm({ setSearchResults, recordsToShow }) {
  const { userDetails } = useUser();
  console.log("uss", userDetails);
  const currentUserEmail = userDetails.email;
  const [firstName, setFirstName] = useState("");
  const [isActive, setIsActive] = useState("");

  const handleSearch = () => {
    execute(
      "GET",
      `${
        protectedResources.api.baseEndPoint
      }/customerUsers/searchFellowCustomerUsers?${new URLSearchParams({
        firstName,
        isActive,
        currentUserEmail,
      })}`
    )
      .then((response) => {
        // Handle the response data as needed
        setSearchResults(response.data);
        console.log("Search Results:", response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleIsActiveChange = (e) => {
    setIsActive(e);
  };

  return (
    <section className="flex flex-col items-stretch border border-gray-400 bg-gray-200 p-5 my-5 w-full h-1/5 -mt-2">
      <h2 className="text-[#002c57] tracking-[0.4px] font-medium text-[16px] leading-normal font-ubuntu md:max-w-full">
        Search Parameter
      </h2>
      <div className="flex flex-wrap justify-between items-center gap-2.5 mt-6">
        <InputField
          label="User Name"
          placeholder="Enter a name"
          value={firstName}
          onChange={handleFirstNameChange}
        />
        <SelectField
          label="Status"
          options={[
            { value: "active", label: "Active" },
            { value: "inactive", label: "Inactive" },
          ]}
          placeholder="Select Status"
          onChange={handleIsActiveChange} // Ensure you have a handler for changes
        />

        <button
          className="rounded-[4px] bg-[#f16622] self-end mt-[28px] px-[18px] py-[10px] text-[#fff] whitespace-nowrap font-[500] text-[20px] font-ubuntu md:whitespace-normal md:px-[20px]"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
    </section>
  );
}

function SelectField({ label, options, placeholder, onChange }) {
  const handleSelectChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <div className="flex-1 flex flex-col px-1.5 mt-2">
      <label className="text-gray-500 text-left font-medium text-sm pb-2.5">
        {label}
      </label>
      <div className="relative flex items-center bg-white border border-gray-400 rounded-sm px-4 py-2.5 w-11/12">
        <select
          className="w-full pr-7 bg-transparent border-none appearance-none font-ubuntu text-base text-gray-500 focus:outline-none"
          onChange={handleSelectChange}
        >
          <option value="">{placeholder}</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <span className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none">
          ▼
        </span>
      </div>
    </div>
  );
}

function InputField({ label, placeholder, value, onChange }) {
  return (
    <div className="flex-1 flex flex-col px-1.5">
      <label className="text-gray-500 text-left font-medium text-sm">
        {label}
      </label>
      <input
        className="border border-gray-300 bg-white mt-3.5 px-5.5 py-2 text-gray-400 text-base font-normal rounded-sm focus:outline-none sm:px-5 font-ubuntu"
        type="text" // Specify the type if necessary, or make it a prop if you need different types
        placeholder={placeholder}
        value={value} // Bind the input value to the value prop
        onChange={onChange} // Propagate the onChange event to the parent component
      />
    </div>
  );
}

function Data({
  custDomain,
  searchResults,
  refreshData,
  setRefreshData,
  index,
}) {
  const { message, model, notification } = App.useApp();
  const { userDetails } = useUser();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  useEffect(() => {
    const fetchRecords = async () => {
      try {
        if (userDetails.email) {
          const response = await execute(
            "GET",
            `${
              protectedResources.api.baseEndPoint
            }/customerUsers/fellowCustomerUsers?email=${encodeURIComponent(
              userDetails.email
            )}`
          );
          setRecords(searchResults || response.data || []);
          setLoading(false);
        }
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchRecords();
  }, [searchResults, refreshData, userDetails.email]); // Refetch data when refreshData changes

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  console.log("records:", records);
  const recordsToShow = userDetails
    ? records.filter((record) => record.Email !== userDetails.email)
    : records;

  const handleEdit = (userId, record) => {
    setCurrentRecord({ ...record, UserId: userId });
    setIsEditModalOpen(true);
  };

  const handleSendInvitation = async (userId, record) => {
    console.log("Send Invitation:", userId, record);
    try {
      var response = await execute(
        "POST",
        `${protectedResources.api.baseEndPoint}/customerUsers/sendSignupInvitation`,
        {
          userId: userId,
        }
      );
      if (response.success) {
        message.success("Invitation sent successfully");
      } else {
        message.error(response.message || "Failed to send invitation");
      }
    } catch (error) {
      message.error("Failed to send invitation: " + error.message);
    }
  };

  const handleDelete = (userId, record) => {
    setCurrentRecord({ ...record, UserId: userId });
    setDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
  };

  const handleSaveEditedUser = (updatedRecord) => {
    setRefreshData(!refreshData); // Trigger data refetch
    setIsEditModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    let newStatus = currentRecord.IsActive ? "inactive" : "active";

    try {
      const response = await execute(
        "DELETE",
        `${protectedResources.api.baseEndPoint}/customerUsers/${currentRecord.UserID}`
      );
      if (response.success) {
        alert(`User status updated successfully to ${newStatus}.`);
        const updatedRecords = records.map((record) =>
          record.UserID === currentRecord.UserID
            ? { ...record, IsActive: newStatus === "active" }
            : record
        );
        setRecords(updatedRecords);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      alert("Failed to modify user: " + error.message);
    }
    setDeleteModalOpen(false);
    setRefreshData(!refreshData); // Trigger data refetch
  };

  return (
    <div className="w-full overflow-y-auto">
      <table className="w-full border-collapse">
        <thead>
          <tr
            className={`cursor-pointer ${index % 2 === 0 ? "bg-gray-100" : ""}`}
          >
            <th className="p-2 text-left border-b border-gray-300 bg-white sticky top-0 z-10">
              Created On
            </th>
            <th className="p-2 text-left border-b border-gray-300 bg-white sticky top-0 z-10">
              First Name
            </th>
            <th className="p-2 text-left border-b border-gray-300 bg-white sticky top-0 z-10">
              Last Name
            </th>
            <th className="p-2 text-left border-b border-gray-300 bg-white sticky top-0 z-10">
              Email
            </th>
            <th className="p-2 text-left border-b border-gray-300 bg-white sticky top-0 z-10">
              Admin
            </th>
            <th className="p-2 text-left border-b border-gray-300 bg-white sticky top-0 z-10">
              Role Description
            </th>
            <th className="p-2 text-left border-b border-gray-300 bg-white sticky top-0 z-10">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {recordsToShow.length > 0 ? (
            recordsToShow.map((record, index) => (
              <tr
                className={`cursor-pointer ${
                  index % 2 === 0 ? "bg-gray-100" : ""
                }`}
                key={index}
              >
                <td className="p-5 border-b border-gray-300">
                  {new Date(record.CreatedOn).toLocaleDateString()}
                </td>
                <td className="p-5 border-b border-gray-300">
                  {record.FirstName}
                </td>
                <td className="p-5 border-b border-gray-300">
                  {record.LastName}
                </td>
                <td className="p-5 border-b border-gray-300">{record.Email}</td>
                <td className="p-5 border-b border-gray-300">
                  {record.IsAdmin ? "Yes" : "No"}
                </td>
                <td className="p-5 border-b border-gray-300">
                  <span
                    className={`inline-block w-[80px] h-[25px] leading-[25px] text-white text-center rounded-[5px] cursor-default p-0 ${
                      record.IsActive ? "bg-[#4CAF50]" : "bg-[#808080]"
                    }`}
                    active={record.IsActive ? "true" : "false"}
                  >
                    {record.IsActive ? "Active" : "Inactive"}
                  </span>
                </td>
                <td className="p-5 border-b border-gray-300">
                  <button
                    className="bg-none border-none cursor-pointer p-[5px] mx-[2px] inline-flex items-center"
                    title="Resend the invitation"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent row click
                      handleSendInvitation(record.UserID, record);
                    }}
                  >
                    <FaEnvelope style={{ color: "#ffad1c" }} />
                  </button>
                  <button
                    className="bg-none border-none cursor-pointer p-[5px] mx-[2px] inline-flex items-center"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent row click
                      handleEdit(record.UserID, record);
                    }}
                  >
                    <FaFilePen style={{ color: "#ffad1c" }} />
                  </button>
                  <button
                    className="bg-none border-none cursor-pointer p-[5px] mx-[2px] inline-flex items-center"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent row click
                      handleDelete(record.UserID, record);
                    }}
                  >
                    <FaTrash style={{ color: "#ffad1c" }} />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr
              className={`cursor-pointer ${
                index % 2 === 0 ? "bg-gray-100" : ""
              }`}
            >
              <td className="p-5 border-b border-gray-300" colSpan="8">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {isEditModalOpen && (
        <EditUserModal
          custDomain={custDomain}
          isOpen={isEditModalOpen}
          onClose={handleCloseModal}
          userData={currentRecord}
          onSave={handleSaveEditedUser}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          record={currentRecord}
          onDeleteConfirmed={handleConfirmDelete}
        />
      )}
    </div>
  );
}

export default AppLayoutUser;
