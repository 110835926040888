export const Faq = () => {
  return (
    <div className="overflow-y-auto w-[100%]">
      <div className="p-[50px] font-arial">
        <h1 className="text-center mb-5 text-[#002c57] text-[40px] font-bold">
          FAQ
        </h1>
        <div>
          <ol className="list-decimal">
            <div className="mb-8">
              <li className="text-[#f16622] text-xl font-bold">
                What is the purpose of this SaaS application?
              </li>
              <p className="text-[#555] text-base leading-relaxed pt-1">
                Our SaaS application is designed to provide Human Health
                Services providers with actionable insights through embedded
                PowerBI reports, helping them optimize operations, improve
                service delivery, and make data-driven decisions.
              </p>
            </div>
            <div className="mb-8">
              <li className="text-[#f16622] text-xl font-bold">
                Who is this SaaS platform for?
              </li>
              <p className="text-[#555] text-[1em] leading-relaxed pt-1">
                The platform is tailored for organizations in the Human Health
                Services sector, including providers in Behavioral Health,
                Intellectual and Developmental Disabilities (IDD), Children &
                Youth Services, and Addiction Services.
              </p>
            </div>
            <div className="mb-8">
              <li className="text-[#f16622] text-xl font-bold">
                What is the MVP1.0 version, and how is it different from future
                releases?
              </li>
              <p className="text-[#555] text-[1em] leading-relaxed pt-1">
                MVP1.0 is our initial release that includes the core
                functionality, such as PowerBI embedded reports, real-time data
                analytics, and a user-friendly interface. Future releases will
                introduce more advanced features based on user feedback from
                this pilot phase.
              </p>
            </div>
            <div className="mb-8">
              <li className="text-[#f16622] text-xl font-bold">
                What types of reports are available in the MVP1.0 release?
              </li>
              <p className="text-[#555] text-[1em] leading-relaxed pt-1">
                The MVP1.0 release includes key data reports on service
                utilization, client demographics, operational efficiency, and
                outcomes, all embedded in PowerBI for easy access and
                interaction.
              </p>
            </div>
            <div className="mb-8">
              <li className="text-[#f16622] text-xl font-bold">
                How do I navigate through the dashboard and reports?
              </li>
              <p className="text-[#555] text-[1em] leading-relaxed pt-1">
                You can easily navigate through the dashboard by using filters
                to focus on specific data areas. Reports are interactive, and
                you can drill down into details by clicking on specific data
                points.
              </p>
            </div>
            <div className="mb-8">
              <li className="text-[#f16622] text-xl font-bold">
                How often is the data in the reports updated?
              </li>
              <p className="text-[#555] text-[1em] leading-relaxed pt-1">
                The data in the reports is updated at regular intervals,
                providing near real-time insights. If necessary, you can trigger
                a manual refresh to get the latest data.
              </p>
            </div>
            <div className="mb-8">
              <li className="text-[#f16622] text-xl font-bold">
                Can I customize the reports for my organization’s specific
                needs?
              </li>
              <p className="text-[#555] text-[1em] leading-relaxed pt-1">
                Not in the current release. In the future releases, users can
                customize the reports by using the PowerBI edit mode to create
                views that focus on the metrics and data points that matter most
                to their organization.
              </p>
            </div>
            <div className="mb-8">
              <li className="text-[#f16622] text-xl font-bold">
                How do I export or share reports with my team or stakeholders?
              </li>
              <p className="text-[#555] text-[1em] leading-relaxed pt-1">
                Reports can be easily exported to common formats such as Excel
                or PDF, enabling you to share them with your team or external
                stakeholders.
              </p>
            </div>
            <div className="mb-8">
              <li className="text-[#f16622] text-xl font-bold">
                Is the platform secure and compliant with data privacy
                regulations?
              </li>
              <p className="text-[#555] text-[1em] leading-relaxed pt-1">
                Absolutely. The platform is fully compliant with data privacy
                regulations, including HIPAA, ensuring that all data is securely
                encrypted and protected throughout the process.
              </p>
            </div>
            <div className="mb-8">
              <li className="text-[#f16622] text-xl font-bold">
                How can I provide feedback on the platform during the pilot
                phase?
              </li>
              <p className="text-[#555] text-[1em] leading-relaxed pt-1">
                We encourage users to share their feedback with us directly
                through the platform’s ‘Feedback’ feature or via email. Your
                insights will help us improve the platform and introduce new
                features in future releases.{" "}
              </p>
            </div>
            <div className="mb-8">
              <li className="text-[#f16622] text-xl font-bold">
                Will the platform be accessible on mobile devices?
              </li>
              <p className="text-[#555] text-[1em] leading-relaxed pt-1">
                While the MVP1.0 release is desktop-focused, we are working on
                making the platform mobile-responsive in future releases,
                allowing you to access reports on smartphones and tablets.
              </p>
            </div>
            <div className="mb-8">
              <li className="text-[#f16622] text-xl font-bold">
                What should I do if I encounter issues or have questions while
                using the platform?
              </li>
              <p className="text-[#555] text-[1em] leading-relaxed pt-1">
                If you encounter any issues or need assistance, our support team
                is available to help. You can reach out through the in-app
                support feature or contact us via email.
              </p>
            </div>
            <div className="mb-8">
              <li className="text-[#f16622] text-xl font-bold">
                Can I set up custom alerts for important metrics?
              </li>
              <p className="text-[#555] text-[1em] leading-relaxed pt-1">
                This feature is currently in development. In future releases,
                users will be able to set custom alerts based on specific data
                thresholds, allowing them to stay informed on critical metrics.
              </p>
            </div>
            <div className="mb-8">
              <li className="text-[#f16622] text-xl font-bold">
                What future enhancements can I expect in the platform?
              </li>
              <p className="text-[#555] text-[1em] leading-relaxed pt-1">
                In addition to mobile accessibility, upcoming features include
                expanded filtering options, ability to upload files, manage
                custom data in an easy to use ‘Data Management’ module and
                custom alert notifications for key performance indicators.
              </p>
            </div>
            <div className="mb-8">
              <li className="text-[#f16622] text-xl font-bold">
                How will my feedback influence future updates?
              </li>
              <p className="text-[#555] text-[1em] leading-relaxed pt-1">
                Your feedback during the pilot phase is crucial in shaping the
                final version of the platform. We will use your insights to
                enhance the product, introduce new features, and improve the
                overall user experience.
              </p>
            </div>
          </ol>
        </div>
      </div>
    </div>
  );
};
