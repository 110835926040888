
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../config/msalConfig';
import { InteractionStatus } from '@azure/msal-browser';
import { AuthenticatedTemplate } from '@azure/msal-react';

const PrivateRoute = ({ element: Component }) => {
    const location = useLocation();
    const { instance, inProgress } = useMsal();

    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    useEffect(() => {
        console.log("PrivateRoute: ", activeAccount, inProgress, instance);
        if(!activeAccount && (inProgress === InteractionStatus.None)) {
            instance.loginRedirect(loginRequest).catch((error) => console.error(error));            
        }
    }, [inProgress, instance, activeAccount]);

    return activeAccount ? (
        React.createElement(Component)
    ) : (
        <AuthenticatedTemplate><Navigate to="/" state={{ from: location }} /></AuthenticatedTemplate>
    );
};

export default PrivateRoute;