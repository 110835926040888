import React from "react";
import styled from "styled-components";

const TitleComponent = ({
  title,
  toggleModal,
  buttonText,
  showButton = false,
}) => {
  return (
    <header className="flex justify-between items-center gap-[10px] w-full mt-[20px] flex-nowrap sm:flex-wrap">
      <h1 className="m-0 text-[30px] font-medium font-ubuntu text-[#002c57] whitespace-nowrap">
        {title}
      </h1>
      {showButton && (
        <button
          className="px-[18px] py-[10px] text-[20px] font-ubuntu text-[#fff] bg-[#f16622] border-none rounded-[4px] cursor-pointer"
          onClick={toggleModal}
        >
          {buttonText}
        </button>
      )}
    </header>
  );
};

export default TitleComponent;
