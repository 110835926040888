import { Descriptions, Skeleton } from "antd";

import TitleComponent from "components/Common/TitleComponent";

import "./index.css";
import { useState } from "react";
import { useUser } from "components/UserContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FilterBar } from "./FilterBar";
import styled from "styled-components";
import { DataManager } from "./DataManager";

export const DataAdministration = () => {
  const { userDetails } = useUser();
  const navigate = useNavigate();
  const [pageFilterData, setPageFilterData] = useState({});

  useEffect(() => {
    if (userDetails && userDetails.IsAdmin === false) {
      console.log(
        "Redirecting to dashboard",
        userDetails,
        !userDetails.IsAdmin
      );
      window.location.href = "/dashboard";
    }
  }, [userDetails, navigate]);

  const handleFilterChange = (filters) => {
    setPageFilterData(filters);
    console.log("handleFilterChange", filters);
  };

  return userDetails.IsAdmin === true ? (
    <div className="overflow-auto flex flex-col w-full gap-[20px]">
      <TitleComponent title={"Data Administration"} />
      <FilterBar onFilterChange={handleFilterChange} />
      <DataManager
        dataset={pageFilterData.selectedModuleDataset}
        datasetColumnMappings={pageFilterData.datasetColumns}
      />
    </div>
  ) : userDetails.IsAdmin === false ? (
    <div className="overflow-auto flex flex-col w-full gap-[20px]">
      <TitleComponent title={"Access Denied"} />
      <p>You do not have access to this page.</p>
    </div>
  ) : (
    <div className="overflow-auto flex flex-col w-full gap-[20px]">
      <TitleComponent title={"Data Administration"} />
      <Descriptions
        title=""
        bordered
        column={{ xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 4 }}
        items={[
          {
            label: "Module",
            children: <Skeleton.Input active />,
          },
          {
            label: "Dataset",
            children: <Skeleton.Input active />,
          },
        ]}
      />
      <div>
        <Skeleton paragraph={{ rows: 1 }} size="large" active />
        <Skeleton paragraph={{ rows: 10 }} active />
      </div>
    </div>
  );
};

//     {
//         title: 'ID',
//         dataIndex: 'id',
//         key: 'id',
//         sorter: (a, b) => a.id.localeCompare(b.id),
//     },
//     {
//         title: 'Title',
//         dataIndex: 'title',
//         key: 'title',
//         sorter: (a, b) => a.title - b.title,
//     },
//     {
//         title: 'Category',
//         dataIndex: 'category',
//         key: 'category',
//         sorter: (a, b) => a.category - b.category,
//     },
//     {
//         title: 'RCG Grouping',
//         dataIndex: 'rcgGrouping',
//         key: 'rcgGrouping',
//         filters: [
//             { text: 'Maximum Capacity', value: 'Maximum Capacity' },
//             { text: 'Minimum Capacity', value: 'Minimum Capacity' },
//         ],
//         onFilter: (value, record) => record.rcgGrouping.includes(value),
//     },
//     {
//         title: 'Service Line',
//         dataIndex: 'serviceLine',
//         key: 'serviceLine',
//         filters: [
//             { text: 'Mental Health', value: 'Mental Health' },
//             { text: 'Physical Health', value: 'Physical Health' },
//         ],
//         onFilter: (value, record) => record.serviceLine.includes(value),
//     },
//     {
//         title: 'Description',
//         dataIndex: 'description',
//         key: 'description',
//         width: '360px',
//         render: (text) => (
//             <div
//                 style={{
//                     whiteSpace: 'nowrap', // Prevent text from wrapping
//                     overflow: 'hidden', // Hide overflow text
//                     textOverflow: 'ellipsis', // Add ellipsis (...) for overflow text
//                     paddingRight: '40px',
//                     color: '#1C1C1C',
//                     fontSize: '16px',
//                     fontWeight: 500,
//                 }}
//                 className="description-cell"
//             >
//                 {text}
//             </div>
//         ),
//     },
//     {
//         title: (
//             <span
//                 style={{ display: 'block', textAlign: 'center', width: '100%' }}
//             >
//                 Action
//             </span>
//         ),
//         key: 'action',
//         align: 'center',
//         render: (text, record) => (
//             <span>
//                 <FontAwesomeIcon
//                     icon={faEdit}
//                     style={{ color: '#F16622', fontSize: '20px' }}
//                 />
//                 <Divider type="vertical" className="custom-divider" />
//                 <FontAwesomeIcon
//                     icon={'fa-solid fa-trash-can'}
//                     style={{ color: '#F16622', fontSize: '20px' }}
//                 />
//             </span>
//         ),
//     },
// ];

// const data = [
//     {
//         key: '1a',
//         id: '1a',
//         title: '100',
//         category: '6400',
//         rcgGrouping: 'Maximum Capacity',
//         serviceLine: 'Mental Health',
//         description:
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//     },
//     {
//         key: '2a',
//         id: '2a',
//         title: '200',
//         category: '6000',
//         rcgGrouping: 'Maximum Capacity',
//         serviceLine: 'Mental Health',
//         description:
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//     },
//     {
//         key: '3a',
//         id: '1b',
//         title: '300',
//         category: '5000',
//         rcgGrouping: 'Maximum Capacity',
//         serviceLine: 'Physical Health',
//         description:
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//     },
//     {
//         key: '4a',
//         id: '3a',
//         title: '400',
//         category: '3000',
//         rcgGrouping: 'Minimum Capacity',
//         serviceLine: 'Physical Health',
//         description:
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//     },
//     {
//         key: '5a',
//         id: '1c',
//         title: '700',
//         category: '2000',
//         rcgGrouping: 'Minimum Capacity',
//         serviceLine: 'Physical Health',
//         description:
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//     },
// ];
// const data = [
//     {
//         key: '1a',
//         id: '1a',
//         title: '100',
//         category: '6400',
//         rcgGrouping: 'Maximum Capacity',
//         serviceLine: 'Mental Health',
//         description:
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//     },
//     {
//         key: '2a',
//         id: '2a',
//         title: '200',
//         category: '6000',
//         rcgGrouping: 'Maximum Capacity',
//         serviceLine: 'Mental Health',
//         description:
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//     },
//     {
//         key: '3a',
//         id: '1b',
//         title: '300',
//         category: '5000',
//         rcgGrouping: 'Maximum Capacity',
//         serviceLine: 'Physical Health',
//         description:
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//     },
//     {
//         key: '4a',
//         id: '3a',
//         title: '400',
//         category: '3000',
//         rcgGrouping: 'Minimum Capacity',
//         serviceLine: 'Physical Health',
//         description:
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//     },
//     {
//         key: '5a',
//         id: '1c',
//         title: '700',
//         category: '2000',
//         rcgGrouping: 'Minimum Capacity',
//         serviceLine: 'Physical Health',
//         description:
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//     },
// ];
// const data = [
//     {
//         key: '1a',
//         id: '1a',
//         title: '100',
//         category: '6400',
//         rcgGrouping: 'Maximum Capacity',
//         serviceLine: 'Mental Health',
//         description:
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//     },
//     {
//         key: '2a',
//         id: '2a',
//         title: '200',
//         category: '6000',
//         rcgGrouping: 'Maximum Capacity',
//         serviceLine: 'Mental Health',
//         description:
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//     },
//     {
//         key: '3a',
//         id: '1b',
//         title: '300',
//         category: '5000',
//         rcgGrouping: 'Maximum Capacity',
//         serviceLine: 'Physical Health',
//         description:
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//     },
//     {
//         key: '4a',
//         id: '3a',
//         title: '400',
//         category: '3000',
//         rcgGrouping: 'Minimum Capacity',
//         serviceLine: 'Physical Health',
//         description:
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//     },
//     {
//         key: '5a',
//         id: '1c',
//         title: '700',
//         category: '2000',
//         rcgGrouping: 'Minimum Capacity',
//         serviceLine: 'Physical Health',
//         description:
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//     },
// ];

// // SLC Data Mapping
// const columns2 = [
//     {
//         title: 'Service Location Code',
//         dataIndex: 'SLC',
//         key: 'SLC',
//         sorter: (a, b) => a.id.localeCompare(b.id),
//     },
//     {
//         title: 'Facility Name',
//         dataIndex: 'FN',
//         key: 'FN',
//         sorter: (a, b) => a.title - b.title,
//     },
//     {
//         title: 'Cost Center',
//         dataIndex: 'CC',
//         key: 'CC',
//         sorter: (a, b) => a.category - b.category,
//     },
//     {
//         title: 'Program Type',
//         dataIndex: 'PT',
//         key: 'PT',
//         filters: [
//             { text: 'Maximum Capacity', value: 'Maximum Capacity' },
//             { text: 'Minimum Capacity', value: 'Minimum Capacity' },
//         ],
//     },
//     {
//         title: 'Program Service Name',
//         dataIndex: 'PSN',
//         key: 'PSN',
//         filters: [
//             { text: 'Maximum Capacity', value: 'Maximum Capacity' },
//             { text: 'Minimum Capacity', value: 'Minimum Capacity' },
//         ],
//     },
//     {
//         title: 'Region',
//         dataIndex: 'Region',
//         key: 'Region',
//     },
//     {
//         title: (
//             <span
//                 style={{ display: 'block', textAlign: 'center', width: '100%' }}
//             >
//                 Action
//             </span>
//         ),
//         key: 'action',
//         align: 'center',
//         render: (text, record) => (
//             <span style={{ display: 'flex', justifyContent: 'center' }}>
//                 <FontAwesomeIcon
//                     icon={faEdit}
//                     style={{ color: '#F16622', fontSize: '20px' }}
//                 />
//                 <Divider type="vertical" className="custom-divider" />
//                 <FontAwesomeIcon
//                     icon={'fa-solid fa-trash-can'}
//                     style={{ color: '#F16622', fontSize: '20px' }}
//                 />
//             </span>
//         ),
//     },
// ];
// const data2 = [
//     {
//         key: '11',
//         id: '1aa',
//         SLC: '0057',
//         FN: '222 Bedford St',
//         CC: '01',
//         PT: 'ICF',
//         PSN: 'ICF',
//         Region: 'North',
//     },
//     {
//         key: '21',
//         id: '1aa',
//         SLC: '0070',
//         FN: '35 Main St',
//         CC: '02',
//         PT: 'Residential',
//         PSN: 'CLA Res Hab - 4 Bed',
//         Region: 'South',
//     },
//     {
//         key: '31',
//         id: '1aa',
//         SLC: '0170',
//         FN: '431 Hungerford St',
//         CC: '03',
//         PT: 'Residential',
//         PSN: 'CLA Res Hab - 4 Bed',
//         Region: 'North',
//     },
// ];
// // SLC Data Mapping
// const columns2 = [
//     {
//         title: 'Service Location Code',
//         dataIndex: 'SLC',
//         key: 'SLC',
//         sorter: (a, b) => a.id.localeCompare(b.id),
//     },
//     {
//         title: 'Facility Name',
//         dataIndex: 'FN',
//         key: 'FN',
//         sorter: (a, b) => a.title - b.title,
//     },
//     {
//         title: 'Cost Center',
//         dataIndex: 'CC',
//         key: 'CC',
//         sorter: (a, b) => a.category - b.category,
//     },
//     {
//         title: 'Program Type',
//         dataIndex: 'PT',
//         key: 'PT',
//         filters: [
//             { text: 'Maximum Capacity', value: 'Maximum Capacity' },
//             { text: 'Minimum Capacity', value: 'Minimum Capacity' },
//         ],
//     },
//     {
//         title: 'Program Service Name',
//         dataIndex: 'PSN',
//         key: 'PSN',
//         filters: [
//             { text: 'Maximum Capacity', value: 'Maximum Capacity' },
//             { text: 'Minimum Capacity', value: 'Minimum Capacity' },
//         ],
//     },
//     {
//         title: 'Region',
//         dataIndex: 'Region',
//         key: 'Region',
//     },
//     {
//         title: (
//             <span
//                 style={{ display: 'block', textAlign: 'center', width: '100%' }}
//             >
//                 Action
//             </span>
//         ),
//         key: 'action',
//         align: 'center',
//         render: (text, record) => (
//             <span style={{ display: 'flex', justifyContent: 'center' }}>
//                 <FontAwesomeIcon
//                     icon={faEdit}
//                     style={{ color: '#F16622', fontSize: '20px' }}
//                 />
//                 <Divider type="vertical" className="custom-divider" />
//                 <FontAwesomeIcon
//                     icon={'fa-solid fa-trash-can'}
//                     style={{ color: '#F16622', fontSize: '20px' }}
//                 />
//             </span>
//         ),
//     },
// ];
// const data2 = [
//     {
//         key: '11',
//         id: '1aa',
//         SLC: '0057',
//         FN: '222 Bedford St',
//         CC: '01',
//         PT: 'ICF',
//         PSN: 'ICF',
//         Region: 'North',
//     },
//     {
//         key: '21',
//         id: '1aa',
//         SLC: '0070',
//         FN: '35 Main St',
//         CC: '02',
//         PT: 'Residential',
//         PSN: 'CLA Res Hab - 4 Bed',
//         Region: 'South',
//     },
//     {
//         key: '31',
//         id: '1aa',
//         SLC: '0170',
//         FN: '431 Hungerford St',
//         CC: '03',
//         PT: 'Residential',
//         PSN: 'CLA Res Hab - 4 Bed',
//         Region: 'North',
//     },
// ];
// // SLC Data Mapping
// const columns2 = [
//     {
//         title: 'Service Location Code',
//         dataIndex: 'SLC',
//         key: 'SLC',
//         sorter: (a, b) => a.id.localeCompare(b.id),
//     },
//     {
//         title: 'Facility Name',
//         dataIndex: 'FN',
//         key: 'FN',
//         sorter: (a, b) => a.title - b.title,
//     },
//     {
//         title: 'Cost Center',
//         dataIndex: 'CC',
//         key: 'CC',
//         sorter: (a, b) => a.category - b.category,
//     },
//     {
//         title: 'Program Type',
//         dataIndex: 'PT',
//         key: 'PT',
//         filters: [
//             { text: 'Maximum Capacity', value: 'Maximum Capacity' },
//             { text: 'Minimum Capacity', value: 'Minimum Capacity' },
//         ],
//     },
//     {
//         title: 'Program Service Name',
//         dataIndex: 'PSN',
//         key: 'PSN',
//         filters: [
//             { text: 'Maximum Capacity', value: 'Maximum Capacity' },
//             { text: 'Minimum Capacity', value: 'Minimum Capacity' },
//         ],
//     },
//     {
//         title: 'Region',
//         dataIndex: 'Region',
//         key: 'Region',
//     },
//     {
//         title: (
//             <span
//                 style={{ display: 'block', textAlign: 'center', width: '100%' }}
//             >
//                 Action
//             </span>
//         ),
//         key: 'action',
//         align: 'center',
//         render: (text, record) => (
//             <span style={{ display: 'flex', justifyContent: 'center' }}>
//                 <FontAwesomeIcon
//                     icon={faEdit}
//                     style={{ color: '#F16622', fontSize: '20px' }}
//                 />
//                 <Divider type="vertical" className="custom-divider" />
//                 <FontAwesomeIcon
//                     icon={'fa-solid fa-trash-can'}
//                     style={{ color: '#F16622', fontSize: '20px' }}
//                 />
//             </span>
//         ),
//     },
// ];
// const data2 = [
//     {
//         key: '11',
//         id: '1aa',
//         SLC: '0057',
//         FN: '222 Bedford St',
//         CC: '01',
//         PT: 'ICF',
//         PSN: 'ICF',
//         Region: 'North',
//     },
//     {
//         key: '21',
//         id: '1aa',
//         SLC: '0070',
//         FN: '35 Main St',
//         CC: '02',
//         PT: 'Residential',
//         PSN: 'CLA Res Hab - 4 Bed',
//         Region: 'South',
//     },
//     {
//         key: '31',
//         id: '1aa',
//         SLC: '0170',
//         FN: '431 Hungerford St',
//         CC: '03',
//         PT: 'Residential',
//         PSN: 'CLA Res Hab - 4 Bed',
//         Region: 'North',
//     },
// ];
