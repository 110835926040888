export const PrivacyPolicy = () => {
  return (
    <div className="w-[100%] container mx-auto px-4 pt-6 pb-20">
      <header class="mb-6">
        <h1 className="text-4xl font-bold text-center text-[#002c57]">
          Privacy Policy
        </h1>
        <p className="text-center mt-2 text-lg text-[#f16622] font-bold">
          Last Updated: <span class="font-normal">12/20/2024</span>
        </p>
      </header>
      <div className="bg-white border border-gray-100 p-5 pl-10 mt-6 rounded-xl shadow-xl">
        <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-14">
          <h2 className="text-[32px] font-bold text-[#f16622] mb-4">
            Table of Contents
          </h2>
          <ol className="list-decimal text-base pl-5 space-y-2 text-[#555] font-medium">
            <li>
              <a href="#introduction" className="hover:text-[#f16622]">
                Introduction
              </a>
            </li>
            <li>
              <a href="#children" className="hover:text-[#f16622]">
                Children Under the Age of 16
              </a>
            </li>
            <li>
              <a href="#personal-information" className="hover:text-[#f16622]">
                What is Personal Information
              </a>
            </li>
            <li>
              <a
                href="#information-we-collect"
                className="hover:text-[#f16622]"
              >
                Information We Collect About You and How We Collect It
              </a>
            </li>
            <li>
              <a
                href="#information-we-provide"
                className="hover:text-[#f16622]"
              >
                Information You Provide to Us
              </a>
            </li>
            <li>
              <a
                href="#information-we-collect-automatic"
                className="hover:text-[#f16622]"
              >
                Information We Collect Through Automatic Data Collection
                Technologies
              </a>
            </li>
            <li>
              <a href="#third-party" className="hover:text-[#f16622]">
                Third-Party Use of Cookies and Other Tracking Technologies
              </a>
            </li>
          </ol>
        </div>
        <div id="introduction" className="scroll-mt-20">
          <h1 className="text-2xl text-[#f16622] font-bold">Introduction </h1>
          <p className="leading-6 text-base mt-2">
            The BI Collaborative ("<span className="font-bold">we</span>", "
            <span className="font-bold">us</span>", "
            <span className="font-bold">our</span>", or "
            <span className="font-bold">BIC</span>",) respects your privacy.
            This policy describes how we collect, use, disclose, and process
            personal information in connection with our websites and webpages
            (collectively "<span className="font-bold">Website</span>",) and
            other websites we own and operate that link to this Privacy Policy,
            and the related content, platform, services, products, and other
            functionality offered on or through our services ("
            <span className="font-bold">Services</span>",). It does not address
            our privacy practices relating to BIC employees and other personnel.{" "}
          </p>
          <p className="leading-6 text-base mt-2">
            Please read this policy carefully to understand our policies and
            practices regarding your information and how we will treat it. If
            you do not agree with our policies and practices, your choice is not
            to use our Website. This policy may change from time to time.{" "}
          </p>
        </div>
        <div id="children" className="mt-14 scroll-mt-20">
          <h1 className="text-2xl text-[#f16622] font-bold">
            Children Under the Age of 16
          </h1>
          <p className="leading-6 text-base mt-2">
            Our Website is not intended for children under 16 years of age. No
            one under age 16 may provide any information to the Website. We do
            not knowingly collect personal information from children under 16.
            If you are under 16, do not use or provide any information on this
            Website. If we learn we have collected or received personal
            information from a child under 16, we will delete that information.
            If you believe we might have any information from or about a child
            under 16, please contact us at{" "}
            <span className="text-[#f16622]">privacy@thebicollab.com</span>.
          </p>
        </div>
        <div id="personal-information" className="mt-14 scroll-mt-20">
          <h1 className="text-2xl text-[#f16622] font-bold">
            What is Personal Information
          </h1>
          <p className="leading-6 text-base mt-2">
            When we use the term "
            <span className="font-bold">personal information</span>" in this
            Privacy Policy, we mean any data or information that identifies,
            relates to, describes, is capable of being associated with, or could
            reasonably be linked, directly or indirectly, with a particular
            natural person or any other data or information that constitutes "
            <span className="font-bold">personal data</span>", "
            <span className="font-bold">personal information</span>", or
            “personally identifiable information” under applicable privacy laws.
          </p>
        </div>
        <div id="information-we-collect" className="mt-14 scroll-mt-20">
          <h1 className="text-2xl text-[#f16622] font-bold">
            Information We Collect About You and How We Collect It
          </h1>
          <p className="leading-6 text-base mt-2">
            We collect several types of information from and about users of our
            Website, including information:
          </p>

          <ul className="list-disc pl-[18px] text-base leading-6 mt-2">
            <li className="mb-1">
              Such as first and last name, Email(in whole or in part), personal
              or business name, any other identifier by which you may be
              contacted online or offline;{" "}
            </li>
            <li className="mb-1">
              Provided in custom messages sent through forms, in chat messages,
              to one of our email addresses, or via phone. This also includes
              contact information provided on our Website. We use this
              information to investigate and respond to your inquiries, to
              communicate with you, to enhance the services we offer to our
              users and to manage and grow our organization;
            </li>
            <li className="mb-1">
              Information that is about you but generally does not identify you
              individually, such as cookies, pixels, tags, beacons, scripts, or
              other tracking technology ("
              <span className="font-bold">Tracking Technologies</span>") placed
              by us (or our partners) on our Website or by our partners on
              third-party websites; and/or
            </li>
            <li>
              Information you provide us as a job applicant, such as employment
              history, education, background, resume, CV, cover letter,
              professional references, or social media accounts like LinkedIn.
            </li>
          </ul>
          <h1 className="leading-6 text-lg mt-4 font-bold">
            We collect this information:{" "}
          </h1>
          <ul className="list-disc pl-5 text-base leading-6 mt-2">
            <li className="mb-1">
              Directly from you when you provide it to us.
            </li>
            <li className="mb-1">
              Automatically as you navigate through the site. Information
              collected automatically may include usage details, IP addresses,
              and information collected through cookies, web beacons, and other
              tracking technologies.
            </li>
            <li>From third parties, for example, our business partners.</li>
          </ul>
        </div>
        <div id="information-we-provide" className="mt-14 scroll-mt-20">
          <h1 className="text-2xl text-[#f16622] font-bold">
            Information You Provide to Us
          </h1>
          <p className="leading-6 text-base mt-2">
            The information we collect on or through our Website may include:
          </p>

          <ul className="list-disc pl-[18px] text-base leading-6 mt-2">
            <li className="mb-2">
              Information that you provide by filling in forms on our Website.
              This includes information provided at the time of requesting
              further information. We may also ask you for information when you
              report a problem with our Website.
            </li>
            <li className="mb-2">
              Records and copies of your correspondence (including email
              addresses), if you contact us.
            </li>
            <li>Your search queries on the Website.</li>
          </ul>
        </div>
        <div
          id="information-we-collect-automatic"
          className="mt-14 scroll-mt-20"
        >
          <h1 className="text-2xl text-[#f16622] font-bold">
            Information We Collect Through Automatic Data Collection
            Technologies
          </h1>
          <p className="leading-6 text-base mt-2">
            We, and our third-party partners, automatically collect information
            you provide to us and information about how you access and use our
            Website, read our emails, or otherwise engage with us. We typically
            collect this information through Tracking Technologies and we may
            use third-party partners or technologies to collect this
            information. Information we collect automatically about you may be
            combined with other personal information we collect directly from
            you or receive from other sources.
          </p>
          <p className="leading-6 text-base mt-2">
            The Tracking Technologies we use for this automatic data collection
            may include:
          </p>

          <ul className="list-disc pl-[18px] text-base leading-6 mt-2">
            <li className="mb-2">
              <span className="font-bold">Cookies (or browser cookies).</span> A
              cookie is a small file placed on the hard drive of your computer.
              You may refuse to accept browser cookies through the cookie
              consent banner made available on our Website or by activating the
              appropriate setting on your browser. However, if you select this
              setting you may be unable to access certain parts of our Website.
              Unless you have adjusted your browser setting so that it will
              refuse cookies, our system will issue cookies that are necessary
              for parts of our website to function when you direct your browser
              to our Website.
            </li>
            <li className="">
              <span className="font-bold">Web Beacons.</span> Pages of our
              Website and our emails may contain small electronic files known as
              web beacons (also referred to as clear gifs, pixel tags, and
              single-pixel gifs) that permit BIC, for example, to count users
              who have visited those pages or opened an email and for other
              related website statistics (for example, recording the popularity
              of certain website content and verifying system and server
              integrity).
            </li>
            <p className="leading-6 text-base mt-2">
              We, and our third-party partners, use Tracking Technologies to
              automatically collect usage and device information, such as:
            </p>
            <li className="my-2 text-base">
              <span className="font-bold">
                {" "}
                Information about the way you access and use our services,
              </span>{" "}
              for example, the site from which you came and the site to which
              you are going when you leave our services, how frequently you
              access the Website, whether you open emails or click the links
              contained in emails, whether you access the services from multiple
              devices, and other actions you take on the Sites.
            </li>
            <li className="mb-2 text-base">
              <span className="font-bold">
                {" "}
                Information about how you use the Website,
              </span>{" "}
              such as the pages you visit, the links you click, the ads you view
              and click on, videos you watch, and other similar actions. We may
              also use third-party tools to collect information you provide to
              us or information about how you use the Website and may record
              your mouse movements, scrolling, clicks and keystroke activity on
              the Website and other browsing, search or purchasing behavior.
              These tools may also record information you enter when you
              interact with our Website or engage in chat features through our
              Website.
            </li>
            <li className="mb-2 text-base">
              <span className="font-bold">
                {" "}
                Information about the computer, tablet, smartphone or other
                device you use,
              </span>{" "}
              such as your IP address, browser type, Internet service provider,
              device type/model/manufacturer, operating system, date and time
              stamp, and a unique ID that allows us to uniquely identify your
              browser, mobile device, or your account (including, for example, a
              persistent device identifier or an Ad ID), and other such
              information. We may also work with third-party partners to employ
              technologies, including the application of statistical modeling
              tools, which permit us to recognize and contact you across
              multiple devices.
            </li>
            <li className="mb-2 text-base">
              <span className="font-bold">
                Information about your location,
              </span>{" "}
              including GPS coordinates (e.g., latitude and/or longitude) or
              similar information regarding the location of your mobile device,
              or we may be able to approximate or infer a device’s location by
              analyzing other information, like an IP address.
            </li>
            <li className="text-base">
              <span className="font-bold">Analytics information.</span> We may
              collect analytics data or use third-party analytics tools such as
              Google Analytics to help us measure traffic and usage trends for
              the services and to understand more about the demographics of our
              users. You can learn more about Google’s practices at{" "}
              <a
                className="text-[#f16622] hover:text-[#f16622] underline"
                href="http://www.google.com/policies/privacy/partners"
              >
                http://www.google.com/policies/privacy/partners
              </a>{" "}
              and view its opt-out options at{" "}
              <a
                className="text-[#f16622] hover:text-[#f16622] underline"
                href="https://tools.google.com/dlpage/gaoptout. "
              >
                https://tools.google.com/dlpage/gaoptout.
              </a>
            </li>
          </ul>
          <p className="leading-6 text-base mt-4">
            All of the information collected automatically through these tools
            allows us to improve your customer experience. For example, we may
            use this information to enhance and personalize your user
            experience, to monitor and improve our Website, and to improve the
            effectiveness of our Website, offers, advertising, communications
            and customer service. We also use this information to: (a) remember
            information so that you will not have to re-enter it during your
            visit or the next time you visit the site; (b) provide custom,
            personalized content and information, including targeted content and
            advertising; (c) identify you across multiple devices; (d) provide
            and monitor the effectiveness of our services; (e) monitor aggregate
            metrics such as total number of visitors, traffic, usage, and
            demographic patterns on our website; (f) diagnose or fix technology
            problems; and (g) otherwise to plan for and enhance our services.
          </p>
          <p className="leading-6 text-base mt-2">
            If you would prefer not to accept cookies, you can manage your
            preferences through the cookie consent banner on our website and
            most browsers will allow you to: (i) change your browser settings to
            notify you when you receive a cookie, which lets you choose whether
            or not to accept it; (ii) disable existing cookies; or (iii) set
            your browser to automatically reject cookies; however, doing so may
            negatively impact your experience using the services, as some
            features and services may not work properly. You may also set your
            e-mail options to prevent the automatic downloading of images that
            may contain technologies that would allow us to know whether you
            have accessed our e-mail and performed certain functions with it.
          </p>
          <p className="leading-6 text-base mt-2">
            Below are some links to external resources you may find helpful
            regarding how different browsers handle Tracking Technologies. These
            resources are completely external to BIC and BIC makes no guarantees
            or warranties as to their content. If your browser is not listed
            below, you should consult the documentation that your browser’s
            provider makes available.
          </p>
          <div className="flex flex-col text-base gap-4 mt-4 ml-4">
            <a
              className="text-[#f16622] underline hover:text-[#f16622]"
              href="https://support.google.com/chrome/?visit_id=637522157167381137-791661483&rd=2#topic=7439538"
            >
              Google Chrome{" "}
            </a>
            <a
              className="text-[#f16622] underline hover:text-[#f16622]"
              href="https://support.mozilla.org/en-US/kb/firefox-options-preferences-and-settings"
            >
              Mozilla Firefox
            </a>
            <a
              className="text-[#f16622] underline hover:text-[#f16622]"
              href="https://support.microsoft.com/en-us/microsoft-edge/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
            >
              Microsoft Edge
            </a>
            <a
              className="text-[#f16622] underline hover:text-[#f16622]"
              href="https://support.apple.com/guide/safari/clear-your-browsing-history-sfri47acf5d6/13.0/mac/10.15"
            >
              Apple Safari
            </a>
          </div>
          <p className="leading-6 text-base my-4">
            We may link or combine the personal data we collect about you and
            the information we collect automatically. This helps us to improve
            our Website and to deliver a better and more personalized service,
            including by enabling us to:
          </p>
          <ul className="list-disc pl-[18px] text-base leading-6 mt-2">
            <li className="mb-2">
              Estimate our audience size and usage patterns.
            </li>
            <li className="mb-2">
              Store information about your preferences, allowing us to deliver a
              better, more personalized experience.{" "}
            </li>
            <li className="mb-2">Speed up your searches. </li>
            <li>Recognize you when you return to our Website.</li>
          </ul>
        </div>
        <div id="third-party" className="mt-14 scroll-mt-20">
          <h1 className="text-xl text-[#f16622] font-bold">
            Third-Party Use of Cookies and Other Tracking Technologies
          </h1>
          <p className="leading-6 text-base mt-2">
            We may participate in interest-based advertising and use third party
            advertising companies to serve you targeted advertisements based on
            your browsing history. We permit third party online advertising
            networks, social media companies and other third-party services, to
            collect information about your use of our online services over time
            so that they may play or display ads on our Services, on other
            websites or services you may use, and on other devices you may use.
            Typically, though not always, the information used for
            interest-based advertising is collected through tracking
            technologies, such as cookies, web beacons, embedded scripts,
            location-identifying technologies, and similar technology, which
            recognize the device you are using and collect information,
            including click stream information, browser type, time and date you
            visited the Sites, AdID, precise geolocation and other information.
            We may share a common account identifier (such as a hashed email
            address or user ID) with our third-party advertising partners to
            help identify you across devices. We and our third-party partners
            use this information to make the advertisements you see online more
            relevant to your interests, as well as to provide
            advertising-related services such as reporting, attribution,
            analytics and market research. We may also use services provided by
            third parties (such as social media platforms) to serve targeted ads
            to you and others on such platforms. We may do this by providing a
            hashed version of your email address or other information to the
            platform provider.
          </p>
          <p className="leading-6 font-bold text-base mt-5 mb-2">
            Disclosure of Your Information
          </p>
          <p className="leading-6 text-base mt-2">
            We may also share, transmit, disclose, grant access to, make
            available, and provide personal information with and to third
            parties, as follows:
          </p>
          <ul className="list-disc pl-[18px] text-base leading-6 mt-2">
            <li className="mb-2">
              Other Service Providers: In addition to the third parties
              identified above, we engage other third-party service providers
              that perform business or operational services for us or on our
              behalf, such as website hosting, infrastructure provisioning, IT
              services, chat technology, analytics services, employment
              application-related services, payment processing services, and
              administrative services.
            </li>
            <li className="mb-2">
              Business Transaction or Reorganization: We may take part in or be
              involved with a corporate business transaction, such as a merger,
              acquisition, joint venture, or financing or sale of company
              assets. We may disclose personal information to a third party
              during negotiation of, in connection with or as an asset in such a
              corporate business transaction. Personal information may also be
              disclosed in the event of insolvency, bankruptcy or receivership.
            </li>
            <li className="mb-2">
              Legal Obligations and Rights: We may disclose personal information
              to third parties, such as legal advisors and law enforcement:{" "}
            </li>
            <li className="mb-2">
              in connection with the establishment, exercise, or defense of
              legal claims;
            </li>
            <li className="mb-2">
              to comply with laws or to respond to lawful requests and legal
              process;
            </li>
            <li className="mb-2">
              to protect our rights and property and the rights and property of
              others, including to enforce our agreements and policies;
            </li>
            <li className="mb-2">to detect, suppress, or prevent fraud;</li>
            <li className="mb-2">
              to protect the health and safety of us and others; or
            </li>
            <li className="mb-2">as otherwise required by applicable law.</li>
            <li>
              <span className="font-bold">With Your Consent:</span> We may
              disclose personal information about an individual to certain other
              third parties or publicly with their consent or direction. For
              example, with an individual’s consent or direction we may post
              their testimonial on our Sites or service-related publications.
            </li>
          </ul>
          <p className="leading-6 font-bold text-base mt-5">Data Retention</p>
          <p className="leading-6 text-base mt-2">
            We will usually store the personal information we collect about you
            for no longer than necessary to fulfil the purposes for which it was
            collected, and in accordance with our legitimate business interests
            and applicable law. However, if necessary, we may retain personal
            data for longer periods of time, until set retention periods and
            deadlines expire, for instance where we are required to do so in
            accordance with legal, tax and accounting requirements set by a
            legislature, regulator or other government authority. To determine
            the appropriate duration of the retention of personal data, we
            consider the amount, nature and sensitivity of the personal data,
            the potential risk of harm from unauthorized use or disclosure of
            personal data and if we can attain our objectives by other means, as
            well as our legal, regulatory, tax, accounting and other applicable
            obligations.
          </p>
          <p className="leading-6 font-bold text-base mt-5">
            Choices About How We Use and Disclose Your Information
          </p>
          <p className="leading-6 text-base mt-2">
            We strive to provide you with choices regarding the personal
            information you provide to us. We have created mechanisms to provide
            you with the following control over your information:
          </p>
          <ul className="list-disc pl-[18px] text-base leading-6 mt-2">
            <li className="mb-2">
              Email Communications Preferences. You can stop receiving
              promotional email communications from us by clicking on the
              “unsubscribe” link provided in such communications. You may not
              opt-out of service-related communications (e.g., account
              verification, transactional communications, changes/updates to
              features of the Services, technical and security notices).
            </li>
            <li>
              Modifying or Deleting Your Information. If you have any questions
              about reviewing, modifying, or deleting your information, you can
              contact us directly at{" "}
              <span className="text-[#f16622]">privacy@thebicollab.com</span>.
              We may not be able to modify or delete your information in all
              circumstances.
            </li>
          </ul>
          <p className="leading-6 text-base mt-2">
            Additional California Consumer Privacy Rights (CCPA) Disclosures and
            Rights
          </p>
          <p className="leading-6 text-base mt-2">
            The following personal data elements we collect may be classified as
            “sensitive” under the CCPA (“sensitive information”):
          </p>
          <ul className="list-disc pl-[18px] text-base leading-6 mt-2">
            <li className="mb-2">
              Social security, driver’s license, state identification card, or
              passport number;
            </li>
            <li className="mb-2">
              Account log-in in combination with password or credentials
              allowing access to an account;
            </li>
            <li className="mb-2">Precise Geolocation</li>
            <li className="mb-2">Racial or Ethnic Origin</li>
            <li className="mb-2">Religious or Philosophical Beliefs</li>
            <li className="mb-2">
              Contents of a Consumer’s Mail, Email, or Text Messages (unless the
              company is the intended recipient of the communication)
            </li>
            <li className="mb-2">
              Biometric information for the purpose of unique identification;
            </li>
            <li className="mb-2">Health Information; and</li>
            <li>Information concerning sex life or sexual orientation.</li>
          </ul>
          <p className="leading-6 text-base mt-2">
            We use this sensitive information for the purposes set forth in the
            Information We Collect About You and How We Collect It section of
            our Privacy Policy. Depending on your state of residency and subject
            to certain legal limitations and exceptions, you may be able to
            limit, or withdraw your consent for, our processing of sensitive
            information (as described in more detail below).
          </p>
          <p className="leading-6 text-base mt-2">
            CCPA “Sale” or “Share” Notice – In the previous 12 months, we may
            have sold or shared the following categories of information (as each
            term is defined in the CCPA), to our third-party business partners:
          </p>
          <ul className="list-disc pl-[18px] text-base leading-6 mt-2">
            <li className="mb-2">Identifiers</li>
            <li className="mb-2">Contact information</li>
            <li className="mb-2">
              Internet or other electronic network activity information
            </li>
            <li>Professional or employment-related information</li>
          </ul>
          <p className="leading-6 text-base mt-2">
            The categories of third parties to whom we may sell or share the
            personal data include:
          </p>
          <ul className="list-disc pl-[18px] text-base leading-6 mt-2">
            <li className="mb-2">
              Business and Marketing Partners (if applicable)
            </li>
            <li className="mb-2">
              Online Advertising Networks and Analytics Providers
            </li>
            <li>Social Networks</li>
          </ul>
          <p className="leading-6 text-base font-bold mt-5">
            Your California Privacy Rights
          </p>
          <p className="leading-6 text-base mt-2">
            If you are a resident of California you have the following rights
            under the California Consumer Privacy Act (“CCPA”):
          </p>
          <ul className="list-disc pl-[18px] font-arial text-base leading-6 mt-2">
            <li className="mb-2">
              THE RIGHT TO NON-DISCRIMINATION for exercising your CCPA rights;
            </li>
            <li className="mb-2">
              THE RIGHT TO KNOW about the personal information a business
              collects about you and how it is used and shared;
            </li>
            <li className="mb-2">
              THE RIGHT TO DELETE personal information we collect from you (with
              some exceptions); and
            </li>
            <li className="mb-2">
              THE RIGHT TO OPT-OUT OF SALES of your personal information for
              monetary or other valuable consideration.
            </li>
            <li>
              The Right to Opt Out of the SHARING of your personal information
              for cross-context behavioral advertising purposes (targeted
              advertising).
            </li>
          </ul>
          <p className="leading-6 text-base mt-2">
            To submit a request, please see the sections below for more
            information. When you submit a request, we may need additional
            information about you in order to sufficiently verify your request.
            Depending on the request, verification may require your legal name,
            email address, zip code, company, or phone number. If a third party
            is making the request on your behalf we will require proof that the
            third party is authorized to act on your behalf, in compliance with
            the CCPA. By sharing this information with us, you consent to us
            contacting you to fulfill your request.
          </p>
          <p className="leading-6 text-base font-bold mt-5">
            The Right to Non-Discrimination
          </p>
          <p className="leading-6 text-base mt-2">
            We understand privacy is important and we will not discriminate
            against you for exercising any of your CCPA rights.
          </p>
          <p className="leading-6 text-base font-bold mt-5">
            The Right to Know & The Right to Delete
          </p>
          <p className="leading-6 text-base mt-2">
            To exercise the rights to know and delete, you can contact us at{" "}
            <span className="text-[#f16622] hover:text-[#f16622]">
              privacy@thebicollab.com
            </span>{" "}
            or write to us at the address in the Contact Us section. Please note
            that in certain instances we may not be able to fulfill your
            request. This may be for reasons including not having information
            about you, not being able to verify your request, or because we have
            to maintain your information to meet legal requirements. If we are
            unable to comply with all or a portion of your request, we will
            explain the reasons for declining to comply with the request.
          </p>
          <p className="leading-6 text-base font-bold mt-5">
            The Right to Opt-Out of Sale of Sharing for Targeted Advertising
          </p>
          <p className="leading-6 text-base mt-2">
            For California residents, we may share your information with our
            business partners, which may qualify as a “sale” under California
            law. A sale of information under the CCPA is broader than how you
            might commonly understand the term. Under the CCPA, “sale” includes
            the traditional exchange of money but it also includes any sort of
            benefit we may receive in exchange for your personal information.
            This means a CCPA sale may include instances where we co-host a
            webinar with a business partner and share the attendee list between
            hosting entities. We also share your personal information with
            AdTech providers. As a result, we provide the right to opt-out to
            comply with the CCPA and its definition of “sale” of personal
            information. In addition, we “share” your personal information with
            third parties for cross-context behavioral advertising purposes
            (targeted advertising). The third parties to whom we sell or share
            personal information may use such information for their own purposes
            in accordance with their own privacy policies.
          </p>
          <p className="leading-6 text-base mt-2">
            To exercise any of these rights please contact us at{" "}
            <span className="text-[#f16622] hover:text-[#f16622]">
              privacy@thebicollab.com
            </span>
            . To appeal a decision regarding a consumer rights request contact
            us at{" "}
            <span className="text-[#f16622] hover:text-[#f16622]">
              privacy@thebicollab.com
            </span>
            . Changes to Our Privacy Policy
          </p>
          <p className="leading-6 text-base mt-2">
            It is our policy to post any changes we make to our privacy policy
            on this page. If we make material changes to how we treat our users’
            personal information, we will notify you by email to the email
            address specified in your account and/or through a notice on the
            Website home page. The date the privacy policy was last revised is
            identified at the top of the page. You are responsible for ensuring
            we have an up-to-date active and deliverable email address for you,
            and for periodically visiting our Website and this privacy policy to
            check for any changes.
          </p>
          <p className="leading-6 text-base font-bold mt-5">
            Contact Information
          </p>
          <p className="leading-6 text-base mt-2">
            To ask questions or comment about this privacy policy and our
            privacy practices, contact us at:
          </p>
          <a
            className="text-[#f16622] hover:text-[#f16622] text-base"
            href="privacy@thebicollab.com"
          >
            privacy@thebicollab.com
          </a>
        </div>
      </div>
    </div>
  );
};
