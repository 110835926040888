import React, { useState } from "react";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useUser } from "./UserContext";
import CitationTable from "./CitationTabs/CitationTable";

function AppLayoutCitation() {
  return (
    <div className="flex flex-col w-full gap-5">
      <NewCitation />
      <SearchBar />
      <SearchParameter />
      <CitationTable />
    </div>
  );
}

function NewCitation() {
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { userDetails } = useUser();

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const goToNewPage = () => {
    navigate("/newcitationform"); // Replace '/new-page' with the actual path you want to navigate to
  };

  return (
    <div>
      <header className="flex justify-between items-center gap-2.5 p-5 mt-5 md:flex-wrap">
        <h1 className="m-0 text-3xl font-ubuntu text-[#002c57]">
          Citation Management
        </h1>
        {/* <NewOrganizationButton onClick={toggleModal}>New User</NewOrganizationButton> */}
        {/* <button className="px-3 py-2 bg-[#f16622] text-white border-none rounded-md mr-10 cursor-pointer" onClick={goToNewPage}>New Citation</button> New button to navigate to a new page */}

        <div
          onClick={goToNewPage}
          className="py-2 px-5 mr-40 bg-orange-600 text-white rounded-3xl hover:bg-orange-700 cursor-pointer"
        >
          New Citation
        </div>
      </header>
      {/* <NewOrganizationModal isOpen={modalIsOpen} onClose={toggleModal} userEmail={userDetails.email}  /> */}
    </div>
  );
}

const SearchBar = () => {
  const navigate = useNavigate();

  return (
    <section className="rounded-sm bg-[#f2f2f2] flex gap-5 mt-[-20px] text-sm font-medium tracking-[0.4px] leading-[1.14] px-3.5 py-1.5 sm:flex-wrap sm:pr-5">
      <button className="px-3 py-2 bg-white text-[#f16622] border-none rounded-md cursor-pointer">
        Search
      </button>
      <div className="flex items-center">
        <span className="mr-2">Select Report</span>
        <img
          className="w-4 h-4 cursor-pointer"
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/ead24240980d11180efd88f499655ecc641ad1aa5e35a4a4b2f398fdecd18272?apiKey=1607f544a0344092815fcb145a04f0bb&"
          alt="Dropdown icon"
        />
      </div>
    </section>
  );
};
// const SearchContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 10px;
//   border: 1px solid #ccc;
//   width: 100%;
// `;

// const SearchButton = styled.button`
//   font-family: Ubuntu, sans-serif;
//   border-radius: 4px;
//   background-color: var(--bicollab-black-white-white, #fff);
//   color: var(--bicollab-secondary-color-orange-700, #f16622);
//   white-space: nowrap;
//   padding: 10px;
//   border: none;
//   cursor: pointer;
//   @media (max-width: 991px) {
//     white-space: initial;
//   }
// `;

// const ReportSelector = styled.div`
//   border-radius: 4px;
//   background-color: var(--bicollab-black-white-white, #fff);
//   display: flex;
//   gap: 10px;
//   color: var(--bicollab-black-white-black-500, #515151);
//   padding: 10px;
//   align-items: center;
//   cursor: pointer;
// `;

// const ReportLabel = styled.span`
//   font-family: Ubuntu, sans-serif;
// `;

// const DropdownIcon = styled.img`
//   width: 18px;
//   height: 18px;
//   object-fit: contain;
// `;

// const SearchParameter = () => {
//   const searchFields = [
//     { label: "Audit Date", value: "Select", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/78f974792b86eed9bba892129d890eda3b6fb85e9481ac456ec3f4e823b564a2?apiKey=1607f544a0344092815fcb145a04f0bb&" },
//     { label: "Audit Agency", value: "Select", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&" },
//     { label: "Audit Type", value: "Licensing", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&" },
//     { label: "Status", value: "In Process", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&" },
//     { label: "Reviewer", value: "Enter a name or email address" },

//     { label: "Citation Type", value: "Individual Record", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&" },

//   ];

//   const additionalFields = [
//     { label: "Location", value: "0040 - 831 Bower Hill, California, USA", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/badfcab2d931c46db435172e546460fb81dd51118a2a1c62edf627225e7b36ea?apiKey=1607f544a0344092815fcb145a04f0bb&" },
//     { label: "Customer Name", value: "Enter a name or email address" },
//     // { label: "Citation Type", value: "Individual Record", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&" },
//   ];

//   return (
//     <SearchContainer1>
//       <SearchTitle>Search Parameter</SearchTitle>
//       <SearchFieldsWrapper>
//         {searchFields.map((field, index) => (
//           <SearchField key={index} label={field.label} value={field.value} icon={field.icon} />
//         ))}
//       </SearchFieldsWrapper>
//       <AdditionalFieldsWrapper>
//         {additionalFields.map((field, index) => (
//           <SearchField key={index} label={field.label} value={field.value} icon={field.icon} />
//         ))}
//       </AdditionalFieldsWrapper>
//       <ButtonGroup>
//         <CancelButton>Cancel</CancelButton>
//         <SearchButtonIn>Search</SearchButtonIn>
//       </ButtonGroup>

//       {/* <div className="flex flex-col justify-center items-end mt-[10px] text-[#f16622] text-center font-medium text-[14px] px-[60px] md:px-[20px] md:max-w-full">
//         <span className="font-ubuntu">Advanced Search</span>
//         <img className="aspect-square object-auto object-center w-[17px]" loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/6a976b0d3c98d4f3717e8e0088d317fa6d20a8e190874d762c384551a17efe29?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="" />
//       </div> */}
//     </SearchContainer1>
//   );
// };

// const SearchField = ({ label, value, icon }) => (
//   <FieldWrapper>
//     <FieldLabel>{label}</FieldLabel>
//     <FieldValue>
//       <span>{value}</span>
//       {icon && <FieldIcon loading="lazy" src={icon} alt="" />}
//     </FieldValue>
//   </FieldWrapper>
// );

const SearchParameter = () => {
  const [formData, setFormData] = useState({
    auditDateFrom: "",
    auditDateTo: "",
    auditAgency: "",
    auditType: "Licensing",
    status: "In Process",
    reviewer: "",
    citationType: "Individual Record",
    location: "0040 - 831 Bower Hill, California, USA",
    customerName: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    // Send formData to the backend
    console.log(formData);
  };

  const searchFields = [
    {
      label: "Audit Date From",
      placeholder: "Select",
      name: "auditDateFrom",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/78f974792b86eed9bba892129d890eda3b6fb85e9481ac456ec3f4e823b564a2?apiKey=1607f544a0344092815fcb145a04f0bb&",
    },
    {
      label: "Audit Date To",
      placeholder: "Select",
      name: "auditDateTo",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/78f974792b86eed9bba892129d890eda3b6fb85e9481ac456ec3f4e823b564a2?apiKey=1607f544a0344092815fcb145a04f0bb&",
    },
    {
      label: "Audit Agency",
      placeholder: "Select",
      name: "auditAgency",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&",
    },
    {
      label: "Audit Type",
      placeholder: "Select",
      name: "auditType",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&",
    },
    {
      label: "Status",
      placeholder: "Select",
      name: "status",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&",
    },
  ];

  const additionalFields = [
    {
      label: "Customer Name",
      placeholder: "Enter a name or email address",
      name: "customerName",
    },
    {
      label: "Citation Type",
      name: "citationType",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&",
    },
    {
      label: "Reviewer",
      placeholder: "Enter a name or email address",
      name: "reviewer",
    },
  ];

  return (
    // <section className="rounded-[2px] border border-[#bababc] bg-[#f2f2f2] p-[17px_11px]">
    //   <h2 className="text-[#002c57] tracking-[0.4px] font-[500] text-[18px] leading-[100%] mt-[-9px]">Search Parameter</h2>
    //   <div className="flex mt-[24px] gap-[20px] mb-[-12px] md:flex-wrap">
    //     {searchFields.map((field, index) => (
    //       <SearchField
    //         key={index}
    //         label={field.label}
    //         name={field.name}
    //         value={formData[field.name]}
    //         icon={field.icon}
    //         onChange={handleChange}
    //       />
    //     ))}
    //   </div>
    //   <div className="flex mt-[20px] gap-[10px] px-[1px] md:flex-wrap">
    //     {additionalFields.map((field, index) => (
    //       <SearchField
    //         key={index}
    //         label={field.label}
    //         name={field.name}
    //         value={formData[field.name]}
    //         icon={field.icon}
    //         onChange={handleChange}
    //       />
    //     ))}
    //   </div>
    //   <div className="self-start flex mt-[20px] gap-[13px] text-[20px] font-medium whitespace-nowrap md:whitespace-normal">
    //     <button className="flex justify-center rounded-full border-[1px] border-[#f16622] bg-white text-[#f16622] px-[18px] py-[10px] md:px-[20px] md:whitespace-normal">Cancel</button>
    //     <button className="flex justify-center rounded-full bg-[#f16622] text-white px-[18px] py-[10px] md:px-[20px] md:whitespace-normal" onClick={handleSubmit}>Search</button>
    //   </div>
    // </section>
    <div
      style={{ width: "100%" }}
      className="bg-gray-100 w-full p-6 rounded-lg shadow-md"
    >
      <h2 className="text-2xl font-semibold mb-6 text-gray-700">
        Search Parameter
      </h2>

      {/* Search Fields */}
      <div className="grid grid-cols-5 gap-4 mb-6">
        {searchFields.map((field, index) => (
          <div key={index} className="relative col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              {field.label}
            </label>
            <input
              type="text"
              placeholder={field.placeholder}
              name={field.name}
              value={formData[field.name]}
              onChange={handleChange}
              className="mt-1 p-2 pr-10 w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
            <img
              src={field.icon}
              alt=""
              className="absolute mt-3 right-2 top-1/2 transform -translate-y-1/2 w-8 h-8 pointer-events-none"
            />
          </div>
        ))}
      </div>

      {/* Additional Fields */}
      <div className="w-full grid grid-cols-3 gap-4 mb-6">
        {additionalFields.map((field, index) => (
          <div key={index} className="relative col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              {field.label}
            </label>
            <input
              type="text"
              placeholder={field.placeholder}
              name={field.name}
              value={formData[field.name]}
              onChange={handleChange}
              className="mt-1 p-2 pr-10 w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        ))}
      </div>

      {/* Buttons */}
      <div className="flex justify-start space-x-4">
        <button
          type="button"
          className="py-2 px-5 bg-white-500 text-orange-500 rounded-3xl hover:bg-white-600 border border-orange-500"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={handleSubmit}
          className="py-2 px-5 bg-orange-600 text-white rounded-3xl hover:bg-orange-700"
        >
          Search
        </button>
      </div>
    </div>
  );
};

const SearchField = ({ label, name, value, icon, onChange }) => (
  <div className="flex flex-col">
    <label className="text-[#767676] font-medium text-[14px] font-ubuntu">
      {label}
    </label>
    <div className="flex justify-center items-center border border-[#bbbbbc] bg-white mt-[13px] gap-[10px] text-[16px] text-[#767676] font-normal whitespace-nowrap px-[21px] py-[10px] sm:px-[20px] sm:whitespace-normal">
      <input
        className="border-none outline-none flex-1"
        name={name}
        value={value}
        onChange={onChange}
      />
      {icon && (
        <img
          className="aspect-square object-auto object-center w-[24px]"
          loading="lazy"
          src={icon}
          alt=""
        />
      )}
    </div>
  </div>
);


export default AppLayoutCitation;
