import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { App, Button, Card, Col, Divider, Flex, Image, Row, Typography } from "antd";
import React from "react";
import { useUser } from "../../components/UserContext";
import { changePasswordRequest } from "../../config/msalConfig";
import { FaEnvelope, FaLock, FaPenToSquare } from "react-icons/fa6";

const { Title } = Typography;

export const Profile = () => {
    const { message, model, notification } = App.useApp();
    const { userDetails } = useUser();
    const { instance } = useMsal();
    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    const handleChangePassword = () => {
        instance.loginRedirect(changePasswordRequest)
        .catch(e => {
            message.error(e.message);
            console.error(e);
        });
    };

return (
    <AuthenticatedTemplate>
    {
        activeAccount ?
        <div style={{width: '100%', padding: '10px'}}>
            <Title level={1} style={{textAlign: 'center', marginTop: '25px'}} >My Profile</Title>
            <Row>
                <Col flex={1}>
                    <Card extra={
                            <Button icon={<FaPenToSquare />} type="text" iconPosition='end'>
                                Edit
                            </Button>}>
                        <Flex style={boxStyle} justify="flex-end" align="flex-start">
                            
                        </Flex>
                        <div gap="small" className="flex items-end">
                            <Image src="/images/avatar.webp" width={50} height={50} style={{borderRadius: "50%" }} />
                            <Title level={4}>{userDetails ? userDetails.name : "Loading..."}</Title>
                        </div>                        
                        <Divider />
                            <Title level={5}>PERSONAL DETAILS</Title>
                            <Flex gap="small" justify="flex-start" align="flex-start"> 
                                <Button icon={<FaEnvelope />} type="text" iconPosition='start'>
                                {userDetails ? userDetails.email : "Loading..."}
                                </Button>
                            </Flex>
                        <Divider />
                        <Button 
                            style={{color: '#F16622', border: 'none'}} 
                            variant="link" 
                            icon={<FaLock />} 
                            type="text" iconPosition='start'
                            onClick={handleChangePassword}>
                            Change Password
                        </Button>
                    </Card>
                </Col>
            </Row>
        </div>
        :
        null
    }
    </AuthenticatedTemplate>
);

}

const boxStyle = {
    width: '100%',
    marginTop: 0,
    paddingTop: 0,
  };