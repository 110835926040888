import React, { useState } from "react";
import { App, Button, Form, Input, Slider } from "antd";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import execute from "../../hooks/execute";
import { protectedResources } from "../../config/msalConfig";
const marks = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "10",
};
const Feedback = () => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const location = useLocation();
  const { pathname } = location;
  const { message, model, notification } = App.useApp();
  const [isSending, setIsSending] = useState(false);

  const toggleDrawer = () => {
    setVisible(!visible);
  };

  const clearForm = () => {
    // clear form fields
    form.resetFields();
  };

  const onFinish = async (values) => {
    setIsSending(true);
    console.log("Received values:", values);
    var data = { ...values, page: pathname };
    try {
      // send  data to the server
      const response = await execute(
        "POST",
        `${protectedResources.api.baseEndPoint}/Feedbacks`,
        data
      );
      setIsSending(false);
      if (response.success) {
        clearForm();
        // show success message
        message.success(response.message || "Feedback submitted successfully");
        setVisible(false);
      } else {
        // show error message response.message
        message.error(response.message || "Failed to submit feedback");
      }
    } catch (error) {
      setIsSending(false);
      console.log("Feedback: catch", error, message);
      // show error message
      message.error("Failed to submit feedback");
    }
  };

  const handleFinishFailed = (errorInfo) => {
    message.error("feeback form has some errors");
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Button
        className={`absolute top-[78px] 4k:top-[80px] ${
          visible ? "right-[500px]" : "right-6"
        } text-[#ff8e4d] border-[#767676] bg-white font-semibold transition-[right] duration-300`}
        disabled={isSending}
        size="large"
        onClick={toggleDrawer}
        icon={visible ? <FaChevronRight /> : <FaChevronLeft />}
      >
        Feedback
      </Button>
      <div
        className={`transition-all ${visible ? "open" : ""}`}
        style={{
          minWidth: 500,
          minHeight: 300,
          position: "absolute",
          right: 0,
          display: visible ? "block" : "none",
          top: 100,
          zIndex: 1000,
          background: "transparent",
          transition: "right 0.3s",
          border: "solid 1px #767676",
          transition: "right 0.3s",
        }}
      >
        <div
          style={{
            background: "white",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
            minHeight: 300,
            maxWidth: 500,
            padding: 5,
          }}
        >
          <h2 className="m-0">Missing a feature?</h2>
          <p>
            We are always looking to improve our product. If you have any
            suggestions or feedback, please let us know. Your input is valuable
            to us and helps us prioritize new features.
          </p>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={handleFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="What is the feature you'd like us to add?"
              name="feature"
              rules={[
                {
                  required: true,
                  message: "Please input your feature request!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="General Feedback on this page"
              name="generalFeedback"
              rules={[
                {
                  required: true,
                  message: "Please input your general feedback!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="On a scale of 0-10, how would you rate this feature/page?"
              name="rating"
              rules={[
                { required: true, message: "Please rate this feature/page!" },
              ]}
            >
              <Slider min={0} max={10} marks={marks} />
            </Form.Item>
            <Form.Item>
              <Button disabled={isSending} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
