import React, { useState, useEffect, useMemo } from "react";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useUser } from "./UserContext";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import execute from "../hooks/execute";
import { protectedResources } from "../config/msalConfig";
import { DatePicker } from "antd";

function ProviderPlan() {
  return (
    <div className="flex flex-col w-full gap-5">
      <NewCitation />
      <NavigationBar />
      <ProviderPlanOfCorrectiveAction />
    </div>
  );
}

function NewCitation() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { userDetails } = useUser();

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <div>
      <header className="flex p-[10px] mt-[20px] mr-[-100px] ml-[-170px] sm:flex-wrap">
        <h1 className="m-0 text-[30px] font-ubuntu text-[#002c57]">
          New Citation
        </h1>
        {/* <NewOrganizationButton onClick={toggleModal}>New User</NewOrganizationButton> */}
      </header>
      {/* <NewOrganizationModal isOpen={modalIsOpen} onClose={toggleModal} userEmail={userDetails.email}  /> */}
    </div>
  );
}

// Component for each navigation item
const NavigationItem = ({ text, isActive, onClick }) => (
  <NavItem isActive={isActive} onClick={onClick}>
    {text}
  </NavItem>
);

// Navigation bar component with dynamic active state management
function NavigationBar() {
  const [activeIndex, setActiveIndex] = useState(0); // State to track the active item
  const [displayText, setDisplayText] = useState(""); // State to track text to display when item is clicked
  const navigate = useNavigate();

  const navItems = [
    { text: "Citation", route: "/newcitation" },
    { text: "Provider Plan of Action", route: "/provider-plan" },
    { text: "Additional Corrective Actions", route: "/additional-action" },
    { text: "Review", route: "/review" },
  ];

  const handleItemClick = (index, text, route) => {
    setActiveIndex(index);
    setDisplayText(`You clicked on: ${text}`);
    navigate(route);
  };

  return (
    <section className="rounded-[3px] bg-[#f2f2f2] flex gap-[20px] mt-[-20px] ml-[-160px] mr-[-120px] text-[14px] font-medium tracking-[0.4px] leading-[114%] p-[6px_14px] md:flex-wrap md:pr-[20px]">
      <nav className="navigation-bar">
        {navItems.map((item, index) => (
          <NavigationItem
            key={index}
            text={item.text}
            isActive={index === activeIndex} // Determine if the item is active based on index
            //   onClick={() => handleItemClick(index, item.text)}
            onClick={() => handleItemClick(index, item.text, item.route)}
          />
        ))}
      </nav>
      <div>{displayText}</div> {/* Display text area */}
    </section>
  );
}

const NavItem = styled.button`
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 114%;
  border-radius: 4px; // Rounded corners for a smooth box appearance
  background-color: var(--bicollab-black-white-white, #fff); // Light background
  color: ${(props) =>
    props.isActive
      ? "var(--bicollab-secondary-color-orange-700, #f16622)"
      : "var(--bicollab-black-white-black-500, #515151)"};
  padding: 10px 15px; // Slightly increased padding for better visual spacing
  border: 2px solid var(--bicollab-black-white-grey-300, #ddd); // Visible border to emphasize the box
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); // Subtle shadow for a 3D effect
  cursor: pointer;
  transition: all 0.3s ease; // Smooth transition for hover effects
  margin-right: 10px;
  &:hover {
    background-color: var(
      --bicollab-grey-200,
      #f7f7f7
    ); // Change background on hover
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15); // Increase shadow on hover for a "lifted" effect
  }

  @media (max-width: 991px) {
    white-space: normal; // Ensures text wraps properly in smaller screens
  }
`;

const ProviderPlanOfCorrectiveAction = () => {
  const [selectedValues, setSelectedValues] = useState({
    providerPOCDueDate: null,
    providerPOCStatus: "Licensing",
    providerPOCCompletionDate: null,
    status: "In Process",
  });

  const handleDateChange = (field, date) => {
    setSelectedValues((prev) => ({ ...prev, [field]: date }));
  };

  const handleDropdownChange = (field, value) => {
    setSelectedValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleAddAction = () => {
    console.log("Add action clicked");
  };

  const handleCancel = () => {
    console.log("Cancel clicked");
  };

  const handleSave = async () => {
    console.log("Save clicked");
    console.log(selectedValues);
  };

  const handleAdditionalActions = () => {
    console.log("Additional Corrective Actions clicked");
  };

  const dropdownOptions = [
    {
      label: "Provider POC Due Date",
      field: "providerPOCDueDate",
      isDate: true,
    },
    {
      label: "Provider POC Status",
      field: "providerPOCStatus",
      options: ["Licensing", "Option 1", "Option 2"],
    },
    {
      label: "Provider POC Completion Date",
      field: "providerPOCCompletionDate",
      isDate: true,
    },
    {
      label: "Status",
      field: "status",
      options: ["In Process", "Option 1", "Option 2"],
    },
  ];

  return (
    <main className="rounded-[2px] flex flex-col p-[17px_11px] mx-auto w-[120%] ml-[-150px]">
      <h1 className="text-[#002c57] font-[500] text-[14px] font-ubuntu tracking-[0.4px]">
        Provider's Plan of Corrective Action
      </h1>
      <section className="flex flex-wrap justify-between gap-5 mt-6">
        {dropdownOptions.map((option, index) =>
          option.isDate ? (
            <DatePickerField
              key={index}
              label={option.label}
              selected={selectedValues[option.field]}
              onChange={(date) => handleDateChange(option.field, date)}
            />
          ) : (
            <DropdownField
              key={index}
              label={option.label}
              value={selectedValues[option.field]}
              options={option.options}
              onChange={(value) => handleDropdownChange(option.field, value)}
              iconSrc={`http://b.io/ext_${index + 1}-`}
            />
          )
        )}
      </section>
      <button
        className="text-[#f16622] font-[500] text-[14px] font-ubuntu tracking-[0.4px] mt-[20px] bg-none border-none cursor-pointer"
        onClick={handleAddAction}
      >
        Add+
      </button>
      <footer className="flex gap-[13px] mt-[58px] text-[20px] font-medium">
        <button
          className="text-[var(--bicollab-secondary-color-orange-700, #f16622)] bg-[var(--bicollab-black-white-white, #fff)] border-[1px] border-[rgba(241, 102, 34, 1)] rounded-[50px] px-[10px] py-[18px] cursor-pointer font-ubuntu"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          className="text-[var(--bicollab-black-white-white, #fff)] bg-[var(--bicollab-secondary-color-orange-700, #f16622)] border-none rounded-[50px] px-[10px] py-[18px] cursor-pointer font-ubuntu"
          type="button"
          onClick={handleSave}
        >
          Save
        </button>
        <button
          className="flex items-center gap-[10px] text-[var(--bicollab-secondary-color-orange-700, #f16622)] bg-[var(--bicollab-black-white-white, #fff)] px-[10px] py-[18px] border-[1px] border-[rgba(241, 102, 34, 1)] cursor-pointer font-ubuntu"
          onClick={handleAdditionalActions}
        >
          Additional Corrective Actions
          <img
            className="w-5 h-5"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/11a66aa01c9ed3d3ab706b1c18a13560e6d5bb36a3cd8d5d4aeb69e78f7aa86b?apiKey=1607f544a0344092815fcb145a04f0bb&"
            alt=""
          />
        </button>
      </footer>
    </main>
  );
};

const DatePickerField = ({ label, selected, onChange }) => (
  <div className="flex flex-col">
    <label className="text-[#767676] text-center font-[500] text-[14px] font-ubuntu">
      {label}
    </label>
    <div className="flex justify-center items-center gap-[10px] mt-[13px] p-[10px_21px] border border-[#babab8] rounded-[2px] bg-[#fff] text-[16px] font-[400] font-ubuntu">
      <DatePicker
        selected={selected}
        onChange={onChange}
        dateFormat="yyyy/MM/dd"
        placeholderText="Select Date"
      />
    </div>
  </div>
);

const DropdownField = ({ label, value, options, onChange, iconSrc }) => (
  <div className="flex flex-col">
    <label className="text-[#767676] text-center font-[500] text-[14px] font-ubuntu">
      {label}
    </label>
    <div className="flex justify-center items-center gap-[10px] mt-[13px] px-[21px] py-[10px] border-[1px] border-[#babab8] rounded-[2px] bg-white text-[16px] font-[400] font-ubuntu">
      <select
        className="appearance-none bg-transparent border-none font-ubuntu text-[16px] text-[#1c1c1c] cursor-pointer"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      <img className="w-6 h-6" src={iconSrc} alt="" />
    </div>
  </div>
);

export default ProviderPlan;
