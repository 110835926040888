const ProductNews = () => {
  return (
    <div className="p-5">
      <p className="pb-[15px]">
        We are thrilled to be in the <b>MVP1.0 pilot phase</b> of our product
        development journey. This marks an important milestone as we gear up to
        bring innovative data reporting solutions to Human Health Services
        providers.
      </p>
      <p className="pb-[15px]">
        Here's an overview of what we’ve achieved so far and what's next:
      </p>
      <h1 className="text-center text-xl font-bold py-5">
        Key Features of MVP1.0
      </h1>
      <h2 className="font-bold pb-2.5">Embedded PowerBI Reports:</h2>
      <p className="pb-[15px]">
        Our core feature allows users to access, interact with, and derive
        insights from PowerBI reports within the SaaS application.
      </p>
      <h2 className="font-bold pb-2.5">Real-time Data Analytics:</h2>
      <p className="pb-[15px]">
        We’ve built the infrastructure to deliver near real-time analytics so
        users can make timely decisions based on current data.
      </p>
      <h2 className="font-bold pb-2.5">User-Friendly Interface:</h2>
      <p className="pb-[15px]">
        The dashboard has been designed with a focus on ease of use. We have
        simplified workflows so users can quickly access critical data points
        without navigating through complex menus.
      </p>
      <h2 className="font-bold pb-2.5">Data Security & Compliance:</h2>
      <p className="pb-[15px]">
        As a platform working with Human Health Services data, we prioritize
        data security and compliance with all relevant regulations, including
        HIPAA. All data handling processes are encrypted and secured according
        to the highest industry standards.
      </p>
      <p className="pb-[15px]">
        We are continuously refining the platform based on user feedback, and we
        look forward to rolling out these new features. Your input during the
        pilot phase is invaluable, and we thank you for being a part of this
        journey!
      </p>
    </div>
  );
};

export default ProductNews;
