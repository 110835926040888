import React, { useState, useEffect } from "react";
import styled from "styled-components";
import execute from "../hooks/execute";
import { protectedResources } from "../config/msalConfig";

const ModalForm = styled.form`
  .error {
    border: 1px solid #f00;
  }
`;

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 0 0 0 5px;
`;

const FormLabel = styled.label`
  margin-top: 10px;
  font-weight: bold;
  .error {
    border: 1px solid #f00;
  }
`;

const EditUserModal = ({
  custDomain,
  isOpen,
  onClose,
  userData,
  onSave,
  setDeleteModalOpen,
}) => {
  const [user, setUser] = useState({});
  const [originalUser, setOriginalUser] = useState({});
  const [modulesAssigned, setModulesAssigned] = useState({});
  const [moduleMap, setModuleMap] = useState({});
  const [emailError, setEmailError] = useState(null);
  const [assignedRoles, setAssignedRoles] = useState({
    "East Area 1": false,
    "South Area 1": false,
    "South Area 2": false,
    "South Area 3": false,
    "East Area 2": false,
  });

  useEffect(() => {
    if (userData) {
      setUser(userData);
      setOriginalUser(userData); // Save the original user data for comparison

      // Fetch modules assigned to the user and module names
      fetchModules(userData.Email).then((modules) => {
        fetchAllModules().then((allModules) => {
          const moduleMapState = allModules.reduce((acc, module) => {
            acc[module.ShortName] = module.ModuleName;
            return acc;
          }, {});
          setModuleMap(moduleMapState);

          const modulesState = modules
            .filter((module) => module in moduleMapState)
            .reduce((acc, module) => {
              acc[module] = userData.Modules.includes(module);
              return acc;
            }, {});
          setModulesAssigned(modulesState);
        });
      });

      if (userData.Role) {
        const rolesArray = userData.Role.split(",");
        const rolesObject = rolesArray.reduce((acc, roleName) => {
          acc[roleName.trim()] = true;
          return acc;
        }, {});
        setAssignedRoles({ ...assignedRoles, ...rolesObject });
      }
    }
  }, [userData]);

  const fetchModules = async (userEmail) => {
    try {
      const response = await execute(
        "GET",
        `${protectedResources.api.baseEndPoint}/customerUsers/ByEmail/${userEmail}/organization`
      );
      let modules = response.data.Modules;

      // Ensure modules is an array
      if (!Array.isArray(modules)) {
        modules = modules.split(",").map((module) => module.trim());
      }
      console.log("fetchModules:", modules);
      return modules;
    } catch (error) {
      console.error("Error fetching modules:", error);
      return [];
    }
  };

  const fetchAllModules = async () => {
    try {
      const response = await execute(
        "GET",
        `${protectedResources.api.baseEndPoint}/modules`
      );
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error("Error fetching all modules:", error);
      return [];
    }
  };
  const ValidateEmailDomain = (email) => {
    console.log("custEmail:", email);
    if (!email) {
      setEmailError("Email is required");
    }
    //  check if email is valid and suffix with domain
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError("Invalid email format");
    } else if (email && custDomain && !email.endsWith(custDomain)) {
      setEmailError(
        "Email domain should match with the customer Domain: " + custDomain
      );
    } else {
      setEmailError(null);
      return true;
    }
    return false;
  };
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    if (type === "checkbox") {
      if (name in modulesAssigned) {
        setModulesAssigned((prev) => ({
          ...prev,
          [name]: checked,
        }));
      } else if (name in assignedRoles) {
        setAssignedRoles((prev) => ({
          ...prev,
          [name]: checked,
        }));
      } else {
        setUser((prev) => ({
          ...prev,
          [name]: checked,
        }));
      }
    } else {
      if (name === "Email") {
        ValidateEmailDomain(value);
      }
      setUser((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate email
    if (!ValidateEmailDomain(user.Email)) {
      alert("email domain must match the customer domain: " + custDomain);
      return;
    }

    const roleValue = user.Role;
    const activeModules = Object.keys(modulesAssigned).reduce((acc, key) => {
      if (modulesAssigned[key]) {
        acc[key] = true;
      }
      return acc;
    }, {});
    const activeRoles = Object.keys(assignedRoles).reduce((acc, role) => {
      if (assignedRoles[role]) {
        acc[role] = true;
      }
      return acc;
    }, {});

    // Determine modified fields
    const modifiedFields = Object.keys(userData).filter(
      (key) => userData[key] !== user[key]
    );

    try {
      const response = await execute(
        "PUT",
        `${protectedResources.api.baseEndPoint}/customerUsers`,
        {
          ...user,
          UserId: userData.UserId,
          Role: roleValue,
          modifiedFields,
          activeModules,
          activeRoles,
        }
      );
      if (response.success) {
        onSave(user);
        onClose();
        alert("User updated successfully!");
      } else {
        alert("Failed to update user: " + response.message);
      }
    } catch (error) {
      console.error("Failed to update user:", error);
      alert("Error updating user: " + error.message);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-[1050]">
      <div className="bg-white p-5 rounded-lg shadow-lg w-[500px] z-[1051]">
        <div className="mt-0">Edit User</div>
        <ModalForm onSubmit={handleSubmit}>
          <div className="flex flex-row flex-wrap w-full">
            <FormColumn className="left-column">
              <FormLabel htmlFor="firstName">First Name</FormLabel>
              <input
                className="p-2 mt-1 border border-gray-300 rounded-md"
                id="firstName"
                name="FirstName"
                value={user.FirstName || ""}
                onChange={handleChange}
              />
              <FormLabel htmlFor="email">Email </FormLabel>
              {/*[someemail@{custDomain}] */}
              <input
                className={`p-2 mt-1 border border-gray-300 rounded-md ${
                  emailError && "error"
                }`} // Add error class if email is invalid
                type="email"
                id="email"
                name="Email"
                disabled="true"
                value={user.Email || `@${custDomain}`}
                onChange={handleChange}
              />
              {emailError && <span className="text-red-500">{emailError}</span>}
              <FormLabel>Modules to Assign</FormLabel>
              {Object.keys(modulesAssigned).map((module) => (
                <div className="flex space-x-2 my-1" key={module}>
                  <input
                    type="checkbox"
                    id={module}
                    name={module}
                    checked={modulesAssigned[module]}
                    onChange={handleChange}
                    className="mt-1"
                  />
                  <label htmlFor={module}>{moduleMap[module]}</label>
                </div>
              ))}
            </FormColumn>
            <FormColumn className="right-column">
              <FormLabel htmlFor="lastName">Last Name</FormLabel>
              <input
                className="p-2 mt-1 border border-gray-300 rounded-md"
                id="lastName"
                name="LastName"
                value={user.LastName || ""}
                onChange={handleChange}
              />
              <FormLabel htmlFor="isAdmin">Role</FormLabel>
              <FormLabel htmlFor="isAdmin">
                Admin
                <input
                  type="checkbox"
                  id="isAdmin"
                  name="IsAdmin"
                  checked={user.IsAdmin || false}
                  onChange={handleChange}
                  className="mt-1"
                />
              </FormLabel>
              {/* <FormLabel>Assign Roles</FormLabel>
              {Object.keys(assignedRoles).map((role) => (
                <div key={role}>
                  <input
                  type="checkbox"
                    id={role}
                    name={role}
                    checked={assignedRoles[role]}
                    onChange={handleChange}
                    className="mt-1"
                  />
                  <label htmlFor={role}>{role}</label>
                </div>
              ))} */}
            </FormColumn>
          </div>
          <div className="flex justify-between mt-5">
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded cursor-pointer hover:bg-blue-700"
              type="submit"
            >
              Save Changes
            </button>
            <button
              className="px-4 py-2 bg-gray-600 text-white rounded cursor-pointer hover:bg-gray-700"
              type="button"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </ModalForm>
      </div>
    </div>
  );
};

export default EditUserModal;
