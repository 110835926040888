import { Card, Col, Row } from "antd";

const ModuleRefreshWidget = ({ data }) => {
  return (
    <Card
      title={
        <h3 className="text-[#007ab9] uppercase font-ubuntu font-normal text-base">
          {data.title}
        </h3>
      }
      extra={
        <a className="text-[#f16622] font-ubuntu" href="#">
          View Details
        </a>
      }
    >
      <div className="py-[5px] px-[24px]">
        {data.lastRefresh && (
          <Row>
            <Col span={12}>
              <p>Last Data Refresh</p>
            </Col>
            <Col span={12}>
              <p style={{ color: data.lastRefreshColor, textAlign: "right" }}>
                {data.lastRefresh}
              </p>
            </Col>
          </Row>
        )}
        {data.nextRefresh && (
          <Row>
            <Col span={12}>
              <p>Next Data Refresh</p>
            </Col>
            <Col span={12}>
              <p style={{ color: data.lastRefreshColor, textAlign: "right" }}>
                {data.nextRefresh}
              </p>
            </Col>
          </Row>
        )}
      </div>
    </Card>
  );
};

export default ModuleRefreshWidget;
