import DataWidgetsSection from "./DataWidgetsSection";
import InformationSection from "./InformationSection";

function Dashboard() {
  return (

    <div className="flex flex-col w-[100%] gap-5 overflow-auto">
      {/* <DataWidgetsSection />*/}
      <InformationSection />
    </div>
  );
}

export default Dashboard;
