import { useUser } from "components/UserContext";
export const TermsOfService = () => {
  const { userDetails } = useUser();
  console.log("userDetails", userDetails);
  return (
    <div className={userDetails.email ? "overflow-y-auto w-[100%]" : "mx-auto"}>
      <div className="container mx-auto px-4 pt-6 pb-20">
        {/* Header */}
        <header class="mb-6">
          <h1 className="text-4xl font-bold text-center text-[#002c57]">
            Terms of Service
          </h1>
          <p className="text-center mt-2 text-lg text-[#f16622] font-bold">
            Effective Date: <span class="font-normal">12/20/2024</span>
          </p>
        </header>

        <div className="bg-white border border-gray-100 p-5 pl-10 rounded-xl shadow-xl">
          <section className="mb-14">
            <p className="text-base leading-6">
              Welcome to The BI Collaborative ("
              <span className="font-bold">we</span>", "
              <span className="font-bold">our</span>", or "
              <span className="font-bold">us</span>",). These Terms of Service
              ("<span className="font-bold">Terms</span>"), govern your access
              to and use of our software-as-a-service (SaaS) platform ("
              <span className="font-bold">Service</span>"), that provides
              Business Intelligence (BI) report generation services to the Human
              Health Services agencies. By accessing or using the Service, you
              ("<span className="font-bold">User <span className="font-normal">or</span> you</span>"), agree to these
              Terms. If you do not agree, do not use the Service.
            </p>
          </section>
          {/* Table of Contents */}
          <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-14">
            <h2 className="text-[32px] font-bold text-[#f16622] mb-4">
              Table of Contents
            </h2>
            <ol className="list-decimal text-base pl-5 space-y-2 text-[#555] font-medium">
              <li>
                <a href="#description" className="hover:text-[#f16622]">
                  Description of the Service
                </a>
              </li>
              <li>
                <a href="#eligibility" className="hover:text-[#f16622]">
                  Eligibility
                </a>
              </li>
              <li>
                <a
                  href="#account-registration"
                  className="hover:text-[#f16622]"
                >
                  Account Registration and Security
                </a>
              </li>
              <li>
                <a href="#use-of-service" className="hover:text-[#f16622]">
                  Use of the Service
                </a>
              </li>
              <li>
                <a href="#data-ownership" className="hover:text-[#f16622]">
                  Data Ownership and Privacy
                </a>
              </li>
              <li>
                <a href="#fees-and-payment" className="hover:text-[#f16622]">
                  Fees and Payment
                </a>
              </li>
              <li>
                <a
                  href="#intellectual-property"
                  className="hover:text-[#f16622]"
                >
                  Intellectual Property
                </a>
              </li>
              <li>
                <a href="#warranties" className="hover:text-[#f16622]">
                  Warranties and Disclaimers
                </a>
              </li>
              <li>
                <a href="#indemnification" className="hover:text-[#f16622]">
                  Indemnification
                </a>
              </li>
              <li>
                <a href="#termination" className="hover:text-[#f16622]">
                  Termination
                </a>
              </li>
              <li>
                <a href="#governing-law" className="hover:text-[#f16622]">
                  Governing Law
                </a>
              </li>
              <li>
                <a href="#amendments" className="hover:text-[#f16622]">
                  Amendments
                </a>
              </li>
              <li>
                <a href="#contact-us" className="hover:text-[#f16622]">
                  Contact Us
                </a>
              </li>
            </ol>
          </div>

          {/* Content Sections */}

          <section id="description" className="mb-14 scroll-mt-20">
            <h2 className="text-2xl font-bold text-[#f16622] mb-2">
              1. Description of the Service
            </h2>
            <p className="text-base leading-6">
              The Service aggregates data from multiple applications such as
              Electronic Health Record (EHR) systems, financial systems, HR
              Systems, performs analysis, and generates BI reports. Reports are
              made accessible to authorized users based on the access policies
              defined by your organization.
            </p>
          </section>

          <section id="eligibility" className="mb-14 scroll-mt-20">
            <h2 className="text-2xl font-bold text-[#f16622] mb-2">
              2. Eligibility
            </h2>
            <p className="text-base font-bold leading-6">To use the Service, you must:</p>
            <ul className="list-disc pl-5 space-y-1 text-base">
              <li>Be at least 18 years old.</li>
              <li>
                Have authority to bind your organization to these Terms if you
                are using the Service on its behalf.
              </li>
            </ul>
          </section>

          <section id="account-registration" className="mb-14 scroll-mt-20">
            <h2 className="text-2xl font-bold text-[#f16622] mb-2">
              3. Account Registration and Security
            </h2>
            <div className="list-disc pl-5 space-y-2 text-base">
              <p>
                <span className="font-bold">3.1. Account Creation:</span> You or
                your organization must register for an account to use the
                Service. The information provided during registration must be
                accurate and up to date.
              </p>
              <p>
                <span className="font-bold">3.2. Authentication:</span> Access
                to the Service is secured via authentication mechanisms. You are
                responsible for maintaining the confidentiality of your login
                credentials.
              </p>
              <p>
                <span className="font-bold">3.3. Unauthorized Access:</span>{" "}
                Notify us immediately if you suspect unauthorized access to your
                account.
              </p>
            </div>
          </section>

          <section id="use-of-service" className="mb-14 scroll-mt-20">
            <h2 className="text-2xl font-bold text-[#f16622] mb-2">
              4. Use of the Service
            </h2>
            <div className="list-disc pl-5 space-y-2 text-base">
              <p>
                <span className="font-bold">4.1. Permitted Use: </span> The
                Service is provided solely for lawful purposes and in compliance
                with applicable healthcare laws and regulations, including HIPAA
                (Health Insurance Portability and Accountability Act) where
                applicable.
              </p>
              <div>
                <span className="font-bold">4.2. Prohibited Use: </span> You
                agree not to:
                <div className="list-disc pl-5 space-y-1 text-base">
                  <li>
                    Share login credentials with unauthorized individuals.
                  </li>
                  <li>
                    Reverse-engineer, decompile, or tamper with the Service.
                  </li>
                  <li>
                    Use the Service to store or process unlawful or
                    discriminatory data.
                  </li>
                </div>
              </div>
              <p>
                <span className="font-bold">4.3. Access Policies: </span>{" "}
                Reports generated by the Service are governed by access
                policies. You agree to use and distribute reports only as
                permitted by your role and your organization’s defined policies.
              </p>
            </div>
          </section>
          <section id="data-ownership" className="mb-14 scroll-mt-20">
            <h2 className="text-2xl font-bold text-[#f16622] mb-2">
              5. Data Ownership and Privacy
            </h2>
            <div className="list-disc pl-5 space-y-2 text-base">
              <p>
                <span className="font-bold">5.1. User Data: </span> Your
                organization retains ownership of all data provided to the
                Service. By uploading data, you grant us a limited license to
                process it as needed to provide the Service.
              </p>
              <p>
                <span className="font-bold">5.2. Privacy: </span> We process
                personal data in compliance with our Privacy Policy. Please
                review the Privacy Policy to understand how we handle personal
                data.
              </p>
              <p>
                <span className="font-bold">5.3. Data Security:</span> We
                implement industry-standard security measures to protect your
                data. However, you acknowledge that no system is completely
                secure.
              </p>
            </div>
          </section>
          <section id="fees-and-payment" className="mb-14 scroll-mt-20">
            <h2 className="text-2xl font-bold text-[#f16622] mb-2">
              6. Fees and Payment
            </h2>
            <div className="list-disc pl-5 space-y-2 text-base">
              <p>
                <span className="font-bold">6.1. Subscription Fees: </span>
                Access to the Service is subject to a subscription fee as
                outlined in your Service Agreement.
              </p>
              <p>
                <span className="font-bold">6.2. Payment Terms: </span> Fees are
                payable in advance and are non-refundable unless otherwise
                agreed.
              </p>
              <p>
                <span className="font-bold">6.3. Late Payments: </span> Failure
                to pay on time may result in suspension of your account.
              </p>
            </div>
          </section>
          <section id="intellectual-property" className="mb-14 scroll-mt-20">
            <h2 className="text-2xl font-bold text-[#f16622] mb-2">
              7. Intellectual Property
            </h2>
            <div className="list-disc pl-5 space-y-2 text-base">
              <p>
                <span className="font-bold">7.1. Ownership: </span>We retain all
                rights, title, and interest in the Service, including underlying
                software and documentation.
              </p>
              <p>
                <span className="font-bold">7.2. Restrictions: </span> You may
                not copy, modify, or create derivative works from the Service.
              </p>
            </div>
          </section>
          <section id="warranties" className="mb-14 scroll-mt-20">
            <h2 className="text-2xl font-bold text-[#f16622] mb-2">
              8. Warranties and Disclaimers
            </h2>
            <div className="list-disc pl-5 space-y-2 text-base">
              <p>
                <span className="font-bold">8.1. No Warranty: </span>The Service
                is provided “as is” without any warranties of any kind, express
                or implied.
              </p>
              <p>
                <span className="font-bold">
                  8.2. Limitation of Liability:{" "}
                </span>{" "}
                We are not liable for indirect, incidental, or consequential
                damages arising from your use of the Service.
              </p>
            </div>
          </section>
          <section id="indemnification" className="mb-14 scroll-mt-20">
            <h2 className="text-2xl font-bold text-[#f16622] mb-2">
              9. Indemnification
            </h2>
            <p className="text-base leading-6">
              You agree to indemnify and hold us harmless from claims, damages,
              or expenses arising from your violation of these Terms or misuse
              of the Service.
            </p>
          </section>
          <section id="termination" className="mb-14 scroll-mt-20">
            <h2 className="text-2xl font-bold text-[#f16622] mb-2">
              10. Termination
            </h2>
            <div className="list-disc pl-5 space-y-2 text-base">
              <p>
                <span className="font-bold">10.1. By either party: </span>
                Termination of service is described and agreed upon as outlined
                in your Service Agreement
              </p>
            </div>
          </section>
          <section id="governing-law" className="mb-14">
            <h2 className="text-2xl font-bold text-[#f16622] mb-2">
              11. Governing Law
            </h2>
            <p className="text-base leading-6">
              Governing laws are outlined in the Service Agreement.
            </p>
          </section>
          <section id="amendments" className="mb-14">
            <h2 className="text-2xl font-bold text-[#f16622] mb-2">
              12. Amendments
            </h2>
            <p className="text-base leading-6">
              We may update these Terms from time to time. Continued use of the
              Service after changes indicates your acceptance of the revised
              Terms.
            </p>
          </section>
          <section id="contact-us" className="mb-14">
            <h2 className="text-2xl font-bold text-[#f16622] mb-2">
              13. Contact Us
            </h2>
            <p className="text-base leading-6">
              For questions or concerns about these Terms, contact us at{" "}
              <a
                href="mailto:privacy@thebicollab.com"
                className="text-[#f16622] hover:underline"
              >
                privacy@thebicollab.com
              </a>
              .
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};
