import React, { useState, useEffect, useMemo } from "react";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { useUser } from "./UserContext";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { FaFloppyDisk, FaArrowLeft } from "react-icons/fa6";
import { DatePicker } from "antd";

function AdditionalActions() {
  return (
    <div className="flex flex-col w-[110%] gap-5">
      <NewCitation />
      <NavigationBar />
      <AdditionalInformation />
    </div>
  );
}

function NewCitation() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { userDetails } = useUser();

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <div>
      <header className="flex p-2.5 mr-[-100px] mt-5 ml-[-170px] md:flex-wrap">
        <h1 className="m-0 text-[30px] font-ubuntu text-[var(--bicollab-primary-color-blue-900,#002c57)]">
          New Citation
        </h1>
      </header>
    </div>
  );
}

const NewCitation1 = styled.button`
  padding: 10px 18px;
  font-size: 16px;
  font-family: Ubuntu, sans-serif;
  color: var(--bicollab-black-white-white, #fff);
  background-color: var(--bicollab-secondary-color-orange-700, #f16622);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 790px;
`;

//   const NavigationItem = ({ text, isActive }) => (
//     <NavItem isActive={isActive}>{text}</NavItem>
//   );

//   function NavigationBar() {
//     const navItems = [
//       { text: "Citation", isActive: true },
//       { text: "Provider Plan of Action", isActive: false },
//       { text: "Additional Corrective Actions", isActive: false },
//       { text: "Review", isActive: false },
//     ];

//     return (
//         <SearchContainer>
//       <nav className="navigation-bar">
//         {navItems.map((item, index) => (
//           <NavigationItem key={index} text={item.text} isActive={item.isActive} />
//         ))}
//       </nav>
//      </SearchContainer>
//     );
//   }
// Component for each navigation item
const NavigationItem = ({ text, isActive, onClick }) => (
  <NavItem isActive={isActive} onClick={onClick}>
    {text}
  </NavItem>
);

// Navigation bar component with dynamic active state management
function NavigationBar() {
  const [activeIndex, setActiveIndex] = useState(0); // State to track the active item
  const [displayText, setDisplayText] = useState(""); // State to track text to display when item is clicked
  const navigate = useNavigate();

  const navItems = [
    { text: "Citation", route: "/newcitation" },
    { text: "Provider Plan of Action", route: "/provider-plan" },
    { text: "Additional Corrective Actions" },
    { text: "Review", route: "/review" },
  ];
  const handleItemClick = (index, text, route) => {
    setActiveIndex(index);
    setDisplayText(`You clicked on: ${text}`);
    navigate(route);
  };
  // const handleItemClick = (index, text) => {
  //   setActiveIndex(index);  // Update the active index to the clicked item's index
  //   setDisplayText(`You clicked on: ${text}`);  // Update the display text
  // };

  return (
    <section className="rounded-[3px] bg-[var(--bicollab-black-white-black-100,#f2f2f2)] flex gap-[20px] mt-[-20px] ml-[-160px] mr-[-120px] text-[14px] font-medium tracking-[0.4px] leading-[114%] p-[6px_14px] md:flex-wrap md:pr-[20px]">
      <nav className="navigation-bar">
        {navItems.map((item, index) => (
          <NavigationItem
            key={index}
            text={item.text}
            isActive={index === activeIndex} // Determine if the item is active based on index
            onClick={() => handleItemClick(index, item.text, item.route)}
          />
        ))}
      </nav>
      <div>{displayText}</div> {/* Display text area */}
    </section>
  );
}

const NavItem = styled.button`
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 114%;
  border-radius: 4px; // Rounded corners for a smooth box appearance
  background-color: var(--bicollab-black-white-white, #fff); // Light background
  color: ${(props) =>
    props.isActive
      ? "var(--bicollab-secondary-color-orange-700, #f16622)"
      : "var(--bicollab-black-white-black-500, #515151)"};
  padding: 10px 15px; // Slightly increased padding for better visual spacing
  border: 2px solid var(--bicollab-black-white-grey-300, #ddd); // Visible border to emphasize the box
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); // Subtle shadow for a 3D effect
  cursor: pointer;
  transition: all 0.3s ease; // Smooth transition for hover effects
  margin-right: 10px;
  &:hover {
    background-color: var(
      --bicollab-grey-200,
      #f7f7f7
    ); // Change background on hover
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15); // Increase shadow on hover for a "lifted" effect
  }

  @media (max-width: 991px) {
    white-space: normal; // Ensures text wraps properly in smaller screens
  }
`;

const StyledNavigationBar = styled.nav`
  border-radius: 3px;
  background-color: var(--bicollab-black-white-black-100, #f2f2f2);
  display: flex;
  gap: 12px;
  padding: 6px 14px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    padding-right: 20px;
  }
`;

// const AdditionalInformation = () => {
//   const correctionTypes = [
//     { label: "Corrective Action Type", placeholder: "Select" },
//     { label: "Corrective Action Category", placeholder: "Select" },
//     { label: "Corrective Action Due Date", placeholder: "Select" },
//     { label: "Corrective Action Completion Date", placeholder: "Select" }
//   ];

//   return (
//     <Container>
//       <Header2 className="text-[var(--bicollab-primary-color-blue-900,#002c57)] tracking-[0.4px] font-[500] text-[14px] leading-[114%] font-['Ubuntu',sans-serif] max-w-full sm:max-w-none">Additional Information</Header2>
//       <CorrectionTypeContainer>
//         {correctionTypes.map((type, index) => (
//           <CorrectionTypeField key={index} label={type.label} placeholder={type.placeholder} />
//         ))}
//       </CorrectionTypeContainer>
//       <ResponsiblePartyField />
//       <DescriptionFields />
//       <CommentsField />
//       <button className="text-orange-700 font-ubuntu tracking-wide mt-5 font-medium text-base leading-[1.14] bg-none border-none cursor-pointer sm:max-w-full">Add+</button>
//       <ActionButtons />
//     </Container>
//   );
// };

// const CorrectionTypeField = ({ label, placeholder }) => (
//   <FieldWrapper className="flex flex-col">
//     <label className="text-[#767676] text-center font-medium text-sm font-ubuntu">{label}</label>
//     <div className="flex justify-center border border-[#bbbbbc] bg-white rounded-[2px] mt-[13px] gap-[10px] text-base text-[#bbbbbc] font-normal whitespace-nowrap px-[21px] py-[10px] md:whitespace-normal md:px-[20px] md:py-0">
//       <span className="font-ubuntu my-auto">{placeholder}</span>
//       <img className="aspect-square object-auto object-center w-6" loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="" />
//     </div>
//   </FieldWrapper>
// );

// const ResponsiblePartyField = () => (
//   <>
//     <label className="text-[#767676] text-center font-medium text-sm font-ubuntu">Responsible Party</label>
//     <input className="self-start justify-center border border-[#bababc] bg-white mt-3 text-[#bbbbbc] px-5 py-3 w-full text-base font-normal rounded-sm md:px-5 md:py-3" type="text" placeholder="Enter a name or email address" />
//   </>
// );

// const DescriptionFields = () => (
//   <div className="flex gap-5 mt-5 md:flex-col md:items-stretch md:gap-0 md:max-w-full">
//     <div className="flex flex-col w-1/2 md:w-full md:mt-8">
//       <label className="text-[#767676] text-center font-medium text-sm font-ubuntu">Describe Citation/Violation of Regulation</label>
//       <textarea className="flex justify-center border rounded-sm border-gray-300 bg-white mt-3 text-gray-400 p-5 px-5 text-base font-normal w-full resize-y sm:px-5 md:max-w-full" placeholder="Type in" />
//     </div>
//     <div className="flex flex-col w-1/2 md:w-full md:mt-8">
//       <label className="text-[#767676] text-center font-medium text-sm font-ubuntu">Corrective Action Required By Inspector</label>
//       <textarea className="flex justify-center border rounded-sm border-gray-300 bg-white mt-3 text-gray-400 p-5 px-5 text-base font-normal w-full resize-y sm:px-5 md:max-w-full" placeholder="Type in" />
//     </div>
//   </div>
// );

// const CommentsField = () => (
//   <>
//     <label className="text-[#767676] text-center font-medium text-sm font-ubuntu">Comments/Follow-up Comments</label>
//     <textarea className="flex justify-center border rounded-sm border-gray-300 bg-white mt-3 text-gray-400 p-5 px-5 text-base font-normal w-full resize-y sm:px-5 md:max-w-full" placeholder="Type in" />
//   </>
// );

// const ActionButtons = () => (
//   <div className="self-start flex mt-14 gap-3 text-xl text-orange-700 font-medium whitespace-nowrap sm:mt-10 sm:whitespace-normal">
//     <button className="flex justify-center rounded-full py-2.5 px-4 cursor-pointer border border-orange-600 bg-white text-orange-600 sm:px-5 sm:whitespace-normal">Cancel</button>
//     <button className="font-ubuntu flex justify-center rounded-full py-2.5 px-4 cursor-pointer bg-[#f16622] text-white border-none sm:px-5">Save</button>
//     <button className="font-ubuntu flex justify-center rounded-full py-2.5 px-4 cursor-pointer border border-[#f16622] bg-white text-[#f16622] gap-2.5 items-center sm:px-5">
//       <span>Review</span>
//       <img className="aspect-square object-auto object-center w-5 fill-white" loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/11a66aa01c9ed3d3ab706b1c18a13560e6d5bb36a3cd8d5d4aeb69e78f7aa86b?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="" />
//     </button>
//   </div>
// );
const AdditionalInformation = () => {
  const [formData, setFormData] = useState({
    correctiveActionType: "",
    correctiveActionCategory: "",
    correctiveActionDueDate: null,
    correctiveActionCompletionDate: null,
    responsibleParty: "",
    describeCitation: "",
    correctiveActionRequired: "",
    comments: "",
  });

  const handleDateChange = (field, date) => {
    setFormData((prev) => ({ ...prev, [field]: date }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    console.log("Save clicked");
    console.log(formData);
  };

  return (
    <section className="rounded-[2px] flex flex-col px-[11px] py-[17px] mx-auto w-[120%] -ml-[150px]">
      <h2 className="text-[var(--bicollab-primary-color-blue-900,#002c57)] tracking-[0.4px] font-[500] text-[14px] leading-[114%] font-ubuntu max-w-full sm:max-w-none">
        Additional Information
      </h2>
      <div className="flex mt-6 gap-5 justify-between sm:max-w-full sm:flex-wrap">
        <CorrectionTypeField
          label="Corrective Action Type"
          name="correctiveActionType"
          value={formData.correctiveActionType}
          onChange={handleChange}
        />
        <CorrectionTypeField
          label="Corrective Action Category"
          name="correctiveActionCategory"
          value={formData.correctiveActionCategory}
          onChange={handleChange}
        />
        <DatePickerField
          label="Corrective Action Due Date"
          selected={formData.correctiveActionDueDate}
          onChange={(date) => handleDateChange("correctiveActionDueDate", date)}
        />
        <DatePickerField
          label="Corrective Action Completion Date"
          selected={formData.correctiveActionCompletionDate}
          onChange={(date) =>
            handleDateChange("correctiveActionCompletionDate", date)
          }
        />
      </div>
      <ResponsiblePartyField
        name="responsibleParty"
        value={formData.responsibleParty}
        onChange={handleChange}
      />
      <DescriptionFields
        describeCitation={formData.describeCitation}
        correctiveActionRequired={formData.correctiveActionRequired}
        handleChange={handleChange}
      />
      <CommentsField
        name="comments"
        value={formData.comments}
        onChange={handleChange}
      />
      <button className="text-orange-700 tracking-wide font-ubuntu mt-5 font-medium text-base leading-[1.14] bg-none border-none cursor-pointer sm:max-w-full">
        Add+
      </button>
      <ActionButtons handleSave={handleSave} />
    </section>
  );
};

const CorrectionTypeField = ({ label, name, value, onChange }) => (
  <div className="flex flex-col">
    <label className="text-[#767676] text-center font-medium text-sm font-ubuntu">
      {label}
    </label>
    <div className="flex justify-center border border-[#bbbbbc] bg-white rounded-[2px] mt-[13px] gap-[10px] text-base text-[#bbbbbc] font-normal whitespace-nowrap px-[21px] py-[10px] md:whitespace-normal md:px-[20px] md:py-0">
      <span className="font-ubuntu my-auto">{label}</span>
      <select name={name} value={value} onChange={onChange}>
        <option value="">Select</option>
        <option value="Option 1">Option 1</option>
        <option value="Option 2">Option 2</option>
      </select>
      <img
        className="aspect-square object-auto object-center w-6"
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/88d7b252952d6596502b38d735d093f475cc8d1ad8da2a0836fd4ec998b8d116?apiKey=1607f544a0344092815fcb145a04f0bb&"
        alt=""
      />
    </div>
  </div>
);

const DatePickerField = ({ label, selected, onChange }) => (
  <div className="flex flex-col">
    <label className="text-[#767676] text-center font-medium text-sm font-ubuntu">
      {label}
    </label>
    <div className="flex justify-center rounded-[2px] border border-[rgba(187,187,188,1)] bg-[var(--bicollab-black-white-white,#fff)]">
      <DatePicker
        selected={selected}
        onChange={onChange}
        dateFormat="yyyy/MM/dd"
        placeholderText="Select Date"
      />
    </div>
  </div>
);

const ResponsiblePartyField = ({ name, value, onChange }) => (
  <>
    <label className="text-[#767676] text-center font-medium text-sm font-ubuntu">
      Responsible Party
    </label>
    <input
      className="self-start justify-center border border-[#bababc] bg-white mt-3 text-[#bbbbbc] px-5 py-3 w-full text-base font-normal rounded-sm md:px-5 md:py-3"
      type="text"
      name={name}
      value={value}
      placeholder="Enter a name or email address"
      onChange={onChange}
    />
  </>
);

const DescriptionFields = ({
  describeCitation,
  correctiveActionRequired,
  handleChange,
}) => (
  <div className="flex gap-5 mt-5 md:flex-col md:items-stretch md:gap-0 md:max-w-full">
    <div className="flex flex-col w-1/2 md:w-full md:mt-8">
      <label className="text-[#767676] text-center font-medium text-sm font-ubuntu">
        Describe Citation/Violation of Regulation
      </label>
      <textarea
        className="flex justify-center border rounded-sm border-gray-300 bg-white mt-3 text-gray-400 p-5 px-5 text-base font-normal w-full resize-y sm:px-5 md:max-w-full"
        name="describeCitation"
        value={describeCitation}
        placeholder="Type in"
        onChange={handleChange}
      />
    </div>
    <div className="flex flex-col w-1/2 md:w-full md:mt-8">
      <label className="text-[#767676] text-center font-medium text-sm font-ubuntu">
        Corrective Action Required By Inspector
      </label>
      <textarea
        className="flex justify-center border rounded-sm border-gray-300 bg-white mt-3 text-gray-400 p-5 px-5 text-base font-normal w-full resize-y sm:px-5 md:max-w-full"
        name="correctiveActionRequired"
        value={correctiveActionRequired}
        placeholder="Type in"
        onChange={handleChange}
      />
    </div>
  </div>
);

const CommentsField = ({ name, value, onChange }) => (
  <>
    <label className="text-[#767676] text-center font-medium text-sm font-ubuntu">
      Comments/Follow-up Comments
    </label>
    <textarea
      className="flex justify-center border rounded-sm border-gray-300 bg-white mt-3 text-gray-400 p-5 px-5 text-base font-normal w-full resize-y sm:px-5 md:max-w-full"
      name={name}
      value={value}
      placeholder="Type in"
      onChange={onChange}
    />
  </>
);

const ActionButtons = ({ handleSave }) => (
  <div className="self-start flex mt-14 gap-3 text-xl text-orange-700 font-medium whitespace-nowrap sm:mt-10 sm:whitespace-normal">
    <button className="flex justify-center rounded-full py-2.5 px-4 cursor-pointer border border-orange-600 bg-white text-orange-600 sm:px-5 sm:whitespace-normal">
      <FaArrowLeft style={{ color: "#ffad1c", margin: "0 25px" }} />
    </button>
    <button
      className="font-ubuntu flex justify-center rounded-full py-2.5 px-4 cursor-pointer bg-[#f16622] text-white border-none sm:px-5"
      onClick={handleSave}
    >
      <FaFloppyDisk style={{ color: "#fff", margin: "0 25px" }} />
    </button>
    <button className="font-ubuntu flex justify-center rounded-full py-2.5 px-4 cursor-pointer border border-[#f16622] bg-white text-[#f16622] gap-2.5 items-center sm:px-5">
      <span>Review</span>
      <img
        className="aspect-square object-auto object-center w-5 fill-white"
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/11a66aa01c9ed3d3ab706b1c18a13560e6d5bb36a3cd8d5d4aeb69e78f7aa86b?apiKey=1607f544a0344092815fcb145a04f0bb&"
        alt=""
      />
    </button>
  </div>
);

export default AdditionalActions;
