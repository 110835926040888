import React, { useState, useEffect, useMemo } from "react";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useUser } from "./UserContext";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

import execute from "../hooks/execute";
import { protectedResources } from "../config/msalConfig";
import { DatePicker } from "antd";

function AdditionalActions() {
  return (
    <div className="flex flex-col w-full gap-5">
      <NewCitation />
      <NavigationBar />
      <AuditForm />
    </div>
  );
}

function NewCitation() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { userDetails } = useUser();

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <div>
      <header className="flex p-[10px] mt-[20px] mr-[-100px] ml-[-170px] sm:flex-wrap">
        <h1 className="m-0 text-[30px] font-ubuntu text-[#002c57]">
          New Citation
        </h1>
        {/* <NewOrganizationButton onClick={toggleModal}>New User</NewOrganizationButton> */}
      </header>
      {/* <NewOrganizationModal isOpen={modalIsOpen} onClose={toggleModal} userEmail={userDetails.email}  /> */}
    </div>
  );
}

//   const NavigationItem = ({ text, isActive }) => (
//     <NavItem isActive={isActive}>{text}</NavItem>
//   );

//   function NavigationBar() {
//     const navItems = [
//       { text: "Citation", isActive: true },
//       { text: "Provider Plan of Action", isActive: false },
//       { text: "Additional Corrective Actions", isActive: false },
//       { text: "Review", isActive: false },
//     ];

//     return (
//         <section className="rounded-[3px] bg-[var(--bicollab-black-white-black-100, #f2f2f2)] flex gap-[20px] mt-[-20px] ml-[-160px] mr-[-120px] text-[14px] font-[500] tracking-[0.4px] leading-[114%] p-[6px_14px]">
//       <nav className="navigation-bar">
//         {navItems.map((item, index) => (
//           <NavigationItem key={index} text={item.text} isActive={item.isActive} />
//         ))}
//       </nav>
//      </SearchContainer>
//     );
//   }
// Component for each navigation item
const NavigationItem = ({ text, isActive, onClick }) => (
  <NavItem isActive={isActive} onClick={onClick}>
    {text}
  </NavItem>
);

// Navigation bar component with dynamic active state management
function NavigationBar() {
  const [activeIndex, setActiveIndex] = useState(0); // State to track the active item
  const [displayText, setDisplayText] = useState(""); // State to track text to display when item is clicked
  const navigate = useNavigate();

  const navItems = [
    { text: "Citation", route: "/newcitation" },
    { text: "Provider Plan of Action", route: "/provider-plan" },
    { text: "Additional Corrective Actions", route: "/additional-action" },
    { text: "Review" },
  ];
  const handleItemClick = (index, text, route) => {
    setActiveIndex(index);
    setDisplayText(`You clicked on: ${text}`);
    navigate(route);
  };
  // const handleItemClick = (index, text) => {
  //   setActiveIndex(index);  // Update the active index to the clicked item's index
  //   setDisplayText(`You clicked on: ${text}`);  // Update the display text
  // };

  return (
    <section className="rounded-[3px] bg-[var(--bicollab-black-white-black-100, #f2f2f2)] flex gap-[20px] mt-[-20px] ml-[-160px] mr-[-120px] text-[14px] font-[500] tracking-[0.4px] leading-[114%] p-[6px_14px]">
      <nav className="navigation-bar">
        {navItems.map((item, index) => (
          <NavigationItem
            key={index}
            text={item.text}
            isActive={index === activeIndex} // Determine if the item is active based on index
            //   onClick={() => handleItemClick(index, item.text)}
            onClick={() => handleItemClick(index, item.text, item.route)}
          />
        ))}
      </nav>
      <div>{displayText}</div> {/* Display text area */}
    </section>
  );
}

//   const NavItem = styled.button`
//     font-family: Ubuntu, sans-serif;
//     font-size: 14px;
//     font-weight: 500;
//     letter-spacing: 0.4px;
//     line-height: 114%;
//     border-radius: 4px;
//     background-color: var(--bicollab-black-white-white, #fff);
//     color: ${props => props.isActive ? 'var(--bicollab-secondary-color-orange-700, #f16622)' : 'var(--bicollab-black-white-black-500, #515151)'};
//     padding: 10px;
//     border: none;
//     cursor: pointer;

//     @media (max-width: 991px) {
//       white-space: normal;
//     }
//   `;

const NavItem = styled.button`
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 114%;
  border-radius: 4px; // Rounded corners for a smooth box appearance
  background-color: var(--bicollab-black-white-white, #fff); // Light background
  color: ${(props) =>
    props.isActive
      ? "var(--bicollab-secondary-color-orange-700, #f16622)"
      : "var(--bicollab-black-white-black-500, #515151)"};
  padding: 10px 15px; // Slightly increased padding for better visual spacing
  border: 2px solid var(--bicollab-black-white-grey-300, #ddd); // Visible border to emphasize the box
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); // Subtle shadow for a 3D effect
  cursor: pointer;
  transition: all 0.3s ease; // Smooth transition for hover effects
  margin-right: 10px;
  &:hover {
    background-color: var(
      --bicollab-grey-200,
      #f7f7f7
    ); // Change background on hover
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15); // Increase shadow on hover for a "lifted" effect
  }

  @media (max-width: 991px) {
    white-space: normal; // Ensures text wraps properly in smaller screens
  }
`;

const AuditForm = () => {
  const [formData, setFormData] = useState({
    auditDate: new Date(),
    auditAgency: "",
    auditType: "Licensing",
    status: "In Process",
    reviewer: "",
    additionalReviewer: "",
    citationType: "Individual Record",
    customerName: "",
    location: "",
    regulationNumberDesc: "",
    correctiveActionType: "",
    correctiveActionCategory: "",
    correctiveActionDueDate: new Date(),
    responsibleParty: "",
    correctiveActionCompletionDate: new Date(),
    describeCitation: "",
    correctiveActionRequired: "",
    comments: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (name, date) => {
    setFormData((prev) => ({ ...prev, [name]: date }));
  };

  const handleSave = async () => {
    console.log("Save clicked");
    console.log(formData);
  };

  return (
    <form className="rounded-[2px] flex flex-col p-[17px_11px] mx-auto w-[120%] -ml-[150px] overflow-y-auto max-h-[65vh]">
      <header className="flex justify-between items-center w-full text-[14px] font-medium tracking-[0.4px] leading-[114%] mb-[20px] sm:flex-wrap">
        <h2 className="text-[#002c57] m-0 font-ubuntu">Primary Information</h2>
      </header>

      <div className="flex flex-wrap gap-[20px] justify-between mb-[30px] sm:flex-col">
        <div className="flex flex-col">
          <label className="text-[#767676] text-center font-medium text-[14px] font-ubuntu mb-[13px]">
            Audit Date
          </label>
          <DatePicker
            selected={formData.auditDate}
            onChange={(date) => handleDateChange("auditDate", date)}
          />
        </div>
        <div className="flex flex-col">
          <label className="text-[#767676] text-center font-medium text-[14px] font-ubuntu mb-[13px]">
            Audit Agency
          </label>
          <input
            className="rounded-[2px] border-[1px] border-[#bbbbbc] bg-[#fff] p-[10px_21px] font-normal text-[16px] font-ubuntu text-[#1c1c1c] md:p-[10px]"
            name="auditAgency"
            value={formData.auditAgency}
            onChange={handleChange}
            placeholder="Enter agency name"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-[#767676] text-center font-medium text-[14px] font-ubuntu mb-[13px]">
            Audit Type
          </label>
          <select
            name="auditType"
            value={formData.auditType}
            onChange={handleChange}
          >
            <option value="Licensing">Licensing</option>
            <option value="Option 1">Option 1</option>
            <option value="Option 2">Option 2</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label className="text-[#767676] text-center font-medium text-[14px] font-ubuntu mb-[13px]">
            Status
          </label>
          <select name="status" value={formData.status} onChange={handleChange}>
            <option value="In Process">In Process</option>
            <option value="Option 1">Option 1</option>
            <option value="Option 2">Option 2</option>
          </select>
        </div>
      </div>

      <section className="flex gap-[20px] justify-between mb-[30px] md:flex-wrap">
        <div className="flex flex-col">
          <label className="text-[#767676] text-center font-medium text-[14px] font-ubuntu mb-[13px]">
            Reviewer
          </label>
          <input
            className="rounded-[2px] border-[1px] border-[#bbbbbc] bg-[#fff] p-[10px_21px] font-normal text-[16px] font-ubuntu text-[#1c1c1c] md:p-[10px]"
            name="reviewer"
            value={formData.reviewer}
            onChange={handleChange}
            placeholder="Enter reviewer name"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-[#767676] text-center font-medium text-[14px] font-ubuntu mb-[13px]">
            Additional Reviewer
          </label>
          <input
            className="rounded-[2px] border-[1px] border-[#bbbbbc] bg-[#fff] p-[10px_21px] font-normal text-[16px] font-ubuntu text-[#1c1c1c] md:p-[10px]"
            name="additionalReviewer"
            value={formData.additionalReviewer}
            onChange={handleChange}
            placeholder="Enter additional reviewer name"
          />
        </div>
      </section>

      <hr className="w-full border-none border-t-[1px] border-t-[#767676] my-[20px]" />

      <section className="flex justify-between items-center w-full text-[14px] font-medium tracking-[0.4px] leading-[114%] mb-[20px] sm:flex-wrap">
        <h2 className="text-[#002c57] m-0 font-ubuntu">
          Secondary Information
        </h2>
      </section>

      <div className="flex flex-wrap gap-[20px] justify-between mb-[30px] sm:flex-col">
        <div className="flex flex-col">
          <label className="text-[#767676] text-center font-medium text-[14px] font-ubuntu mb-[13px]">
            Citation Type
          </label>
          <select
            name="citationType"
            value={formData.citationType}
            onChange={handleChange}
          >
            <option value="Individual Record">Individual Record</option>
            <option value="Option 1">Option 1</option>
            <option value="Option 2">Option 2</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label className="text-[#767676] text-center font-medium text-[14px] font-ubuntu mb-[13px]">
            Customer Name
          </label>
          <input
            className="rounded-[2px] border-[1px] border-[#bbbbbc] bg-[#fff] p-[10px_21px] font-normal text-[16px] font-ubuntu text-[#1c1c1c] md:p-[10px]"
            name="customerName"
            value={formData.customerName}
            onChange={handleChange}
            placeholder="Enter customer name"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-[#767676] text-center font-medium text-[14px] font-ubuntu mb-[13px]">
            Location
          </label>
          <input
            className="rounded-[2px] border-[1px] border-[#bbbbbc] bg-[#fff] p-[10px_21px] font-normal text-[16px] font-ubuntu text-[#1c1c1c] md:p-[10px]"
            name="location"
            value={formData.location}
            onChange={handleChange}
            placeholder="Enter location"
          />
        </div>
      </div>

      <p className="text-[#515151] font-normal text-[16px] my-[20px] md:w-full font-ubuntu">
        Input the individual who's record is being reviewed, if applicable
      </p>

      <hr className="w-full border-none border-t-[1px] border-t-[#767676] my-[20px]" />

      <header className="flex justify-between items-center w-full text-[14px] font-medium tracking-[0.4px] leading-[114%] mb-[20px] sm:flex-wrap">
        <h2 className="text-[#002c57] m-0 font-ubuntu">
          Additional Information
        </h2>
      </header>

      <div className="flex flex-wrap gap-[20px] justify-between mb-[30px] sm:flex-col">
        <div className="flex flex-col">
          <label className="text-[#767676] text-center font-medium text-[14px] font-ubuntu mb-[13px]">
            Regulation Number - Desc
          </label>
          <input
            className="rounded-[2px] border-[1px] border-[#bbbbbc] bg-[#fff] p-[10px_21px] font-normal text-[16px] font-ubuntu text-[#1c1c1c] md:p-[10px]"
            name="regulationNumberDesc"
            value={formData.regulationNumberDesc}
            onChange={handleChange}
            placeholder="Enter regulation number"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-[#767676] text-center font-medium text-[14px] font-ubuntu mb-[13px]">
            Corrective Action Type
          </label>
          <select
            name="correctiveActionType"
            value={formData.correctiveActionType}
            onChange={handleChange}
          >
            <option value="Type A">Type A</option>
            <option value="Option 1">Option 1</option>
            <option value="Option 2">Option 2</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label className="text-[#767676] text-center font-medium text-[14px] font-ubuntu mb-[13px]">
            Corrective Action Category
          </label>
          <select
            name="correctiveActionCategory"
            value={formData.correctiveActionCategory}
            onChange={handleChange}
          >
            <option value="Category 1">Category 1</option>
            <option value="Option 1">Option 1</option>
            <option value="Option 2">Option 2</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label className="text-[#767676] text-center font-medium text-[14px] font-ubuntu mb-[13px]">
            Corrective Action Due Date
          </label>
          <DatePicker
            selected={formData.correctiveActionDueDate}
            onChange={(date) =>
              handleDateChange("correctiveActionDueDate", date)
            }
          />
        </div>
        <div className="flex flex-col">
          <label className="text-[#767676] text-center font-medium text-[14px] font-ubuntu mb-[13px]">
            Responsible Party
          </label>
          <input
            className="rounded-[2px] border-[1px] border-[#bbbbbc] bg-[#fff] p-[10px_21px] font-normal text-[16px] font-ubuntu text-[#1c1c1c] md:p-[10px]"
            name="responsibleParty"
            value={formData.responsibleParty}
            onChange={handleChange}
            placeholder="Enter responsible party"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-[#767676] text-center font-medium text-[14px] font-ubuntu mb-[13px]">
            Corrective Action Completion Date
          </label>
          <DatePicker
            selected={formData.correctiveActionCompletionDate}
            onChange={(date) =>
              handleDateChange("correctiveActionCompletionDate", date)
            }
          />
        </div>
      </div>

      <section className="flex gap-[20px] mb-[30px] md:flex-col">
        <div className="flex-1 md:w-full">
          <label className="text-[#767676] text-center font-medium text-[14px] font-ubuntu mb-[13px]">
            Describe Citation/Violation of Regulation
          </label>
          <textarea
            className="w-full rounded-[2px] border-[1px] border-[#bbbbbc] bg-[#fff] p-[19px] md:p-[19px] resize-y min-h-[100px] font-[400] text-[16px] font-ubuntu"
            name="describeCitation"
            value={formData.describeCitation}
            onChange={handleChange}
            placeholder="Type in"
          />
        </div>
        <div className="flex-1 md:w-full">
          <label className="text-[#767676] text-center font-medium text-[14px] font-ubuntu mb-[13px]">
            Corrective Action Required By Inspector
          </label>
          <textarea
            className="w-full rounded-[2px] border-[1px] border-[#bbbbbc] bg-[#fff] p-[19px] md:p-[19px] resize-y min-h-[100px] font-[400] text-[16px] font-ubuntu"
            name="correctiveActionRequired"
            value={formData.correctiveActionRequired}
            onChange={handleChange}
            placeholder="Type in"
          />
        </div>
      </section>

      <label className="text-[#767676] text-center font-medium text-[14px] font-ubuntu mb-[13px]">
        Comments/Follow-up Comments
      </label>
      <textarea
        className="w-full rounded-[2px] border-[1px] border-[#bbbbbc] bg-[#fff] p-[19px] md:p-[19px] resize-y min-h-[100px] font-[400] text-[16px] font-ubuntu"
        name="comments"
        value={formData.comments}
        onChange={handleChange}
        placeholder="Type in"
      />

      <div className="flex gap-[13px] mt-[58px] sm:mt-[40px] sm:flex-wrap">
        <button
          className="font-ubuntu text-[20px] font-[500] rounded-[50px] py-[10px] px-[18px] cursor-pointer bg-[#fff] text-[#f16622] border-[1px] border-[#f16622] sm:px-[10px] sm:w-full"
          type="button"
        >
          Cancel
        </button>
        <button
          className="font-ubuntu text-[20px] font-[500] rounded-[50px] py-[10px] px-[18px] cursor-pointer bg-[#f16622] text-[#fff] border-none sm:px-[10px] sm:w-full"
          type="button"
          onClick={handleSave}
        >
          Save
        </button>
        <button
          className="font-ubuntu text-[20px] font-[500] rounded-[50px] py-[10px] px-[18px] cursor-pointer bg-[#fff] text-[#f16622] border-[1px] border-[#f16622] sm:px-[10px] sm:w-full"
          type="button"
        >
          Finalize
        </button>
      </div>
    </form>
  );
};

export default AdditionalActions;
