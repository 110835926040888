
import { LogLevel } from '@azure/msal-browser';

export const b2cPolicies = {
  names: {
      signIn: 'B2C_1A_CustApp_SIGNIN',
      changePassword: process.env.REACT_APP_AB2C_AUTH_CHANGEPASSWORD_NAME,
      forgotPassword: 'B2C_1A_reset',
      editProfile: 'B2C_1A_edit_profile',
  },
  authorities: {
      signIn: {
          authority: `https://${process.env.REACT_APP_AB2C_AUTH_B2C_TENANT}.b2clogin.com/${process.env.REACT_APP_AB2C_AUTH_B2C_TENANT}.onmicrosoft.com/B2C_1A_CustApp_SIGNIN`,
      },
      changePassword: {
          authority: `https://${process.env.REACT_APP_AB2C_AUTH_B2C_TENANT}.b2clogin.com/${process.env.REACT_APP_AB2C_AUTH_B2C_TENANT}.onmicrosoft.com/B2C_1A_CustApp_CHANGEPASSWORD`,
      },
      forgotPassword: {
          authority: `https://${process.env.REACT_APP_AB2C_AUTH_B2C_TENANT}.b2clogin.com/${process.env.REACT_APP_AB2C_AUTH_B2C_TENANT}.onmicrosoft.com/B2C_1A_CustApp_reset`,
      },
      editProfile: {
          authority: `https://${process.env.REACT_APP_AB2C_AUTH_B2C_TENANT}.b2clogin.com/${process.env.REACT_APP_AB2C_AUTH_B2C_TENANT}.onmicrosoft.com/B2C_1A_CustApp_ProfileEdit`,
      },
  },
  authorityDomain: `${process.env.REACT_APP_AB2C_AUTH_B2C_TENANT}.b2clogin.com`,
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AB2C_AUTH_CLIENTID,
    authority: b2cPolicies.authorities.signIn.authority, 
    knownAuthorities: [b2cPolicies.authorityDomain], 
    redirectUri: `${window.location.origin}/redirect`,
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    // scopes: process.env.REACT_APP_AB2C_AUTH_SCOPES.split(','),
    // validateAuthority: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            //console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      }
    }
  },
};

export const protectedResources = {
  api: {
      baseEndPoint: process.env.REACT_APP_API_BASE_URL,
      scopes: {
          read: [`https://${process.env.REACT_APP_AB2C_AUTH_B2C_TENANT}.onmicrosoft.com/${process.env.REACT_APP_API_CLIENT_ID}/bic.read`
          ],
      },
  },
  databridgeApi: {
    baseEndPoint: process.env.REACT_APP_API_BASE_URL,
    scopes: {
        read: [`https://${process.env.REACT_APP_AB2C_AUTH_B2C_TENANT}.onmicrosoft.com/databridge-api/data.read`],
    },
  },
};

export const loginRequest = {
  scopes: [...protectedResources.api.scopes.read],
};

export const changePasswordRequest = {
  authority: b2cPolicies.authorities.changePassword.authority,
  scopes: [...protectedResources.api.scopes.read],
};