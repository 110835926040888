import { Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Announcements from "./Announcements";
import QuickTips from "./QuickTips";
import ProductNews from "./ProductNews";
import { FaBullhorn, FaLightbulb, FaRadio } from "react-icons/fa6";
import execute from "hooks/execute";
import { protectedResources } from "config/msalConfig";
import { useUser } from "components/UserContext";

const tabItems = [
  {
    key: "1",
    label: (
      <div className="flex items-center gap-2">
        <FaBullhorn />
        <b>Announcement</b>
      </div>
    ),
    children: <Announcements />,
  },
  {
    key: "2",
    label: (
      <div className="flex items-center gap-2">
        <FaLightbulb />
        <b>Quick Tips</b>
      </div>
    ),
    children: <QuickTips />,
  },
  {
    key: "3",
    label: (
      <div className="flex items-center gap-2">
        <FaRadio />
        <b>Product News</b>
      </div>
    ),
    children: <ProductNews />,
  },
];

function InformationSection() {
  const { userDetails } = useUser();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [customerEtls, setCustomerEtls] = useState([]);

  const fetchCustomerEtls = async (values) => {
    console.log(userDetails);
    console.log("fetching Roles");
    setLoading(true);
    try {
      const response = await execute(
        "GET",
        `${protectedResources.api.baseEndPoint}/ETL_management/customer/${userDetails.CustomerID}`
      );
      if (response) {
        if (response.success) {
          console.log("customerEtls");
          console.log(response.data);
          setCustomerEtls(response.data);
          // console.log(orgModules)

          setLoading(false);
          // setSearchResults(response.data.customers); // Assuming customers are part of the response
        } else {
          setError(
            response.message || "No organization found for the specified name"
          );
          setLoading(false);
        }
      }
    } catch (err) {
      setError("Failed to fetch data: " + err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!process.env.REACT_APP_DISABLE_ETL_Feature) {
      fetchCustomerEtls();
    }
  }, [userDetails]);

  const columns = [
    {
      title: "ETL Name",
      dataIndex: "Name",
      key: "Name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "ETL Message",
      dataIndex: "ETL_Message",
      key: "ETL_Message",
    },
    {
      title: "ETL Result",
      dataIndex: "ETL_Result",
      key: "ETL_Result",
    },
  ];

  console.log(process.env.REACT_APP_DISABLE_ETL_Feature);
  return (
    <div className="mt-0 mb-3">
      <div className="bg-[#0693e3] mr-6 py-8 pl-4 lg:pr-10 xl:pr-[30rem] border border-[#002c57] mb-5">
        <div className="p-2">
          <h1 className="text-[#002c57] font-bold lg:text-xl xl:text-3xl">
            Business Intelligence for Human Services
          </h1>
          <p className="pt-2 font-semibold lg:text-sm xl:text-base text-white">
            The BI Collaborative exclusively serves Human Services Providers —
            IDD, Behavioral & Mental Health, Children & Youth, and Addiction
            Service Providers — helping them turn data into actionable
            information to create efficiencies, optimize quality, and drive cost
            and revenue improvements.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-8">
          <Tabs
            className=""
            defaultActiveKey="1"
            type="card"
            size="small"
            items={tabItems}
          />
        </div>
        <div className="flex mr-6 col-span-4 mt-0 2xl:mt-10 justify-center">
          <img
            className="w-[28rem] object-contain"
            src="/human.png"
            alt="human"
          />
        </div>
        {!process.env.REACT_APP_DISABLE_ETL_Feature && (
          <Table
            className="lg:w-[50%] w-full border-gray-200 border-solid rounded-md"
            bordered
            loading={loading}
            title={() => "ETL Information"}
            pagination={false}
            columns={columns}
            dataSource={customerEtls}
          />
        )}
      </div>
    </div>
  );
}

export default InformationSection;
