import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const footerData = [
  {
    text: "Terms of Service",
    url: "/terms-of-service",
  },
  {
    text: "Privacy Policy",
    url: "/privacy-policy",
  },
];

function Footer() {
  return (
    <footer className="bg-white flex flex-col font-medium text-center justify-center w-full">
      <div className="bg-white flex w-full items-center justify-between p-[6px_28px] md:flex-row md:items-center md:justify-between flex-col">
        <p className="text-[#767676] flex-1 text-left m-0 text-[12px] font-ubuntu">
          © The BI Collaborative. All rights reserved.
        </p>
        {!process.env.REACT_APP_HIDE_LOGO && (
          <img
            className="order-[-1] object-contain w-[55px]"
            src={`${process.env.REACT_APP_SHARED_IMAGES_URL}/prism-logo.png`}
            alt="The BI Collaborative Logo"
          />
        )}
        <nav className="flex flex-1 gap-4 text-[14px] text-[#1c1c1c] justify-end m-0 p-2">
          {footerData.map((link, index) => (
            <Link key={index} to={link.url}>
              {link.text}
            </Link>
          ))}
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
