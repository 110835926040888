import React, { useState, useEffect, useMemo } from "react";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useUser } from "./UserContext";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import execute from "../hooks/execute";
import { protectedResources } from "../config/msalConfig";

function Citation() {
  return (
    <div className="flex flex-col w-full gap-5">
      <NewCitation />
      <NavigationBar />
      <AuditForm />
    </div>
  );
}

function NewCitation() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { userDetails } = useUser();

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <div>
      <header className="flex p-2.5 mr-[-100px] mt-5 ml-[-170px] max-w-full sm:flex-wrap">
        <h1 className="m-0 text-3xl font-ubuntu text-[#002c57]">
          New Citation
        </h1>
        {/* <NewOrganizationButton onClick={toggleModal}>New User</NewOrganizationButton> */}
      </header>
      {/* <NewOrganizationModal isOpen={modalIsOpen} onClose={toggleModal} userEmail={userDetails.email}  /> */}
    </div>
  );
}

const NavigationItem = ({ text, isActive, onClick }) => (
  <NavItem isActive={isActive} onClick={onClick}>
    {text}
  </NavItem>
);

// Navigation bar component with dynamic active state management
function NavigationBar() {
  const [activeIndex, setActiveIndex] = useState(0); // State to track the active item
  const [displayText, setDisplayText] = useState(""); // State to track text to display when item is clicked
  const navigate = useNavigate();

  const navItems = [
    { text: "Citation", route: "/citation" },
    { text: "Provider Plan of Action", route: "/provider-plan" },
    { text: "Additional Corrective Actions", route: "/additional-action" },
    { text: "Review", route: "/review" },
  ];

  const handleItemClick = (index, text, route) => {
    setActiveIndex(index);
    setDisplayText(`You clicked on: ${text}`);
    navigate(route);
  };

  return (
    <section className="rounded-[3px] bg-[#f2f2f2] flex gap-5 mt-[-20px] ml-[-160px] text-[14px] font-medium tracking-[0.4px] leading-[114%] px-[14px] py-[6px] sm:flex-wrap sm:px-[20px]">
      <nav className="navigation-bar">
        {navItems.map((item, index) => (
          <NavigationItem
            key={index}
            text={item.text}
            isActive={index === activeIndex} // Determine if the item is active based on index
            onClick={() => handleItemClick(index, item.text, item.route)}
          />
        ))}
      </nav>
      <div>{displayText}</div> {/* Display text area */}
    </section>
  );
}

const NavItem = styled.button`
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 114%;
  border-radius: 4px; // Rounded corners for a smooth box appearance
  background-color: var(--bicollab-black-white-white, #fff); // Light background
  color: ${(props) =>
    props.isActive
      ? "var(--bicollab-secondary-color-orange-700, #f16622)"
      : "var(--bicollab-black-white-black-500, #515151)"};
  padding: 10px 15px; // Slightly increased padding for better visual spacing
  border: 2px solid var(--bicollab-black-white-grey-300, #ddd); // Visible border to emphasize the box
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); // Subtle shadow for a 3D effect
  cursor: pointer;
  transition: all 0.3s ease; // Smooth transition for hover effects
  margin-right: 10px;
  &:hover {
    background-color: var(
      --bicollab-grey-200,
      #f7f7f7
    ); // Change background on hover
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15); // Increase shadow on hover for a "lifted" effect
  }

  @media (max-width: 991px) {
    white-space: normal; // Ensures text wraps properly in smaller screens
  }
`;

const FormField = ({ label, placeholder, type = "text" }) => (
  <div className="flex flex-col">
    <label className="text-[#767676] text-center font-medium text-sm font-[Ubuntu]">
      {label}
    </label>
    <input
      className="justify-center rounded-[2px] border border-[#bbbbbc] bg-white mt-[13px] text-[#767676] px-[21px] py-[13px] font-normal text-base font-[Ubuntu] md:px-[20px]"
      type={type}
      placeholder={placeholder}
      aria-label={label}
    />
  </div>
);

const SelectField = ({ label, options }) => (
  <div className="flex flex-col">
    <label className="text-[#767676] text-center font-medium text-sm font-[Ubuntu]">
      {label}
    </label>
    <div className="relative flex items-center">
      <select className="justify-center rounded-[2px] border border-[#bbbbbc] bg-white mt-[13px] px-[21px] py-[10px] font-normal text-base font-[Ubuntu] appearance-none w-full md:px-[20px]">
        <option value="">Select</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      <img
        className="absolute right-[10px] top-1/2 transform -translate-y-1/2 pointer-events-none"
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/f4e355ff160bac1d9944e0755c79365ca97cb1a508c2fbebb93313db8081fe3d?apiKey=1607f544a0344092815fcb145a04f0bb&"
        alt=""
      />
    </div>
  </div>
);

const TextAreaField = ({ label, placeholder }) => (
  <div className="flex flex-col">
    <label className="text-[#767676] text-center font-medium text-sm font-[Ubuntu]">
      {label}
    </label>
    <textarea
      className="justify-center rounded-[2px] border border-[#bbbbbc] bg-white mt-[13px] text-[#bbbbbc] p-[19px_21px] font-[400] text-[16px] font-ubuntu md:p-[19px_20px]"
      placeholder={placeholder}
      aria-label={label}
    />
  </div>
);

//     return (
//       <form className="rounded-[2px] flex flex-col px-[11px] py-[17px] ml-[-150px] mt-[-8px] mr-[-120px] overflow-y-auto max-h-[65vh]">
//         <section className="mt-6">
//           <h2 className="text-[#002c57] tracking-[0.4px] mt-0 font-[500] text-[14px] leading-[114%] font-ubuntu md:max-w-full">Primary Information</h2>
//           <div className="flex mt-5 gap-5 justify-between md:flex-wrap">
//             <SelectField label="Audit Date" options={[]} />
//             <SelectField label="Audit Agency" options={[]} />
//             <SelectField label="Audit Type" options={['Licensing']} />
//             <SelectField label="Status" options={['In Process']} />
//           </div>
//           <div className="flex mt-5 gap-5 justify-between md:flex-wrap">
//             <FormField label="Reviewer" placeholder="Enter a name or email address" />
//             <FormField label="Additional Reviewer" placeholder="Enter a name or email address" />
//           </div>
//         </section>

//         <hr className="w-full border-0 border-t border-[#767676] my-[10px]" />

//         <section className="mt-6">
//           <h2 className="text-[#002c57] tracking-[0.4px] mt-0 font-[500] text-[14px] leading-[114%] font-ubuntu md:max-w-full">Secondary Information</h2>
//           <div className="flex mt-5 gap-5 justify-between md:flex-wrap">
//             <SelectField label="Citation Type" options={['Individual Record']} />
//             <FormField label="Customer Name" placeholder="Enter a name or email address" />
//             <SelectField label="Location" options={['0040 - 831 Bower Hill, California, USA']} />
//           </div>
//           <p className="text-[#515151] mt-[20px] font-ubuntu font-normal text-[16px]">Input the individual who's record is being reviewed, if applicable</p>
//         </section>

//         <hr className="w-full border-0 border-t border-[#767676] my-[10px]" />

//         <section className="mt-6">
//           <h2 className="text-[#002c57] tracking-[0.4px] mt-0 font-[500] text-[14px] leading-[114%] font-ubuntu md:max-w-full">Additional Information</h2>
//           <div className="flex mt-5 gap-5 justify-between md:flex-wrap">
//             <SelectField label="Regulation Number - Desc" options={[]} />
//             <SelectField label="Corrective Action Type" options={[]} />
//             <SelectField label="Corrective Action Category" options={[]} />
//             <SelectField label="Corrective Action Due Date" options={[]} />
//           </div>
//           <div className="flex mt-5 gap-5 justify-between md:flex-wrap">
//             <FormField label="Responsible Party" placeholder="Enter a name or email address" />
//             <SelectField label="Corrective Action Completion Date" options={[]} />
//           </div>
//           <div className="flex mt-5 gap-5 justify-between md:flex-wrap">
//             <TextAreaField label="Describe Citation/Violation of Regulation" placeholder="Type in" />
//             <TextAreaField label="Corrective Action Required By Inspector" placeholder="Type in" />
//           </div>
//           <TextAreaField label="Comments/Follow-up Comments" placeholder="Type in" />
//         </section>

//         <div className="flex mt-[58px] gap-[13px] sm:flex-wrap sm:mt-[40px]">
//           <button className="font-[500] text-[20px] font-ubuntu rounded-full py-[10px] px-[18px] cursor-pointer sm:px-[20px] text-[#f16622] bg-white border border-[#f16622]">Cancel</button>
//           <button className="font-[500] text-[20px] font-ubuntu rounded-full py-[10px] px-[18px] cursor-pointer sm:px-[20px] text-white bg-[#f16622] border-none">Save</button>
//           <button className="font-[500] text-[20px] font-ubuntu rounded-full py-[10px] px-[18px] cursor-pointer sm:px-[20px] flex items-center gap-[10px] text-[#f16622] bg-white border border-[#f16622]">
//             Provider Plan Of Action
//             <img className="w-5 h-5" loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/11a66aa01c9ed3d3ab706b1c18a13560e6d5bb36a3cd8d5d4aeb69e78f7aa86b?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="" />
//           </button className="font-[500] text-[20px] font-ubuntu rounded-full py-[10px] px-[18px] cursor-pointer sm:px-[20px] flex items-center gap-[10px] text-[#f16622] bg-white border border-[#f16622]">
//         </div>
//       </form>
//     );
//   };
const AuditForm = () => {
  const [formData, setFormData] = useState({
    auditDate: "",
    auditAgency: "",
    auditType: "Licensing",
    status: "In Process",
    reviewer: "",
    additionalReviewer: "",
    citationType: "Individual Record",
    customerName: "",
    location: "0040 - 831 Bower Hill, California, USA",
    regulationNumberDesc: "",
    correctiveActionType: "",
    correctiveActionCategory: "",
    correctiveActionDueDate: "",
    responsibleParty: "",
    correctiveActionCompletionDate: "",
    describeCitation: "",
    correctiveActionRequired: "",
    comments: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    // Send formData to the backend
    console.log(formData);
  };

  return (
    <form
      className="rounded-[2px] flex flex-col px-[11px] py-[17px] ml-[-150px] mt-[-8px] mr-[-120px] overflow-y-auto max-h-[65vh]"
      onSubmit={handleSubmit}
    >
      <section className="mt-6">
        <h2 className="text-[#002c57] tracking-[0.4px] mt-0 font-[500] text-[14px] leading-[114%] font-ubuntu md:max-w-full">
          Primary Information
        </h2>
        <div className="flex mt-5 gap-5 justify-between md:flex-wrap">
          <SelectField
            label="Audit Date"
            name="auditDate"
            options={["Option 1", "Option 2"]}
            value={formData.auditDate}
            onChange={handleChange}
          />
          <SelectField
            label="Audit Agency"
            name="auditAgency"
            options={["Agency 1", "Agency 2"]}
            value={formData.auditAgency}
            onChange={handleChange}
          />
          <SelectField
            label="Audit Type"
            name="auditType"
            options={["Licensing"]}
            value={formData.auditType}
            onChange={handleChange}
          />
          <SelectField
            label="Status"
            name="status"
            options={["In Process"]}
            value={formData.status}
            onChange={handleChange}
          />
        </div>
        <div className="flex mt-5 gap-5 justify-between md:flex-wrap">
          <FormField
            label="Reviewer"
            name="reviewer"
            placeholder="Enter a name or email address"
            value={formData.reviewer}
            onChange={handleChange}
          />
          <FormField
            label="Additional Reviewer"
            name="additionalReviewer"
            placeholder="Enter a name or email address"
            value={formData.additionalReviewer}
            onChange={handleChange}
          />
        </div>
      </section>

      <hr className="w-full border-0 border-t border-[#767676] my-[10px]" />

      <section className="mt-6">
        <h2 className="text-[#002c57] tracking-[0.4px] mt-0 font-[500] text-[14px] leading-[114%] font-ubuntu md:max-w-full">
          Secondary Information
        </h2>
        <div className="flex mt-5 gap-5 justify-between md:flex-wrap">
          <SelectField
            label="Citation Type"
            name="citationType"
            options={["Individual Record"]}
            value={formData.citationType}
            onChange={handleChange}
          />
          <FormField
            label="Customer Name"
            name="customerName"
            placeholder="Enter a name or email address"
            value={formData.customerName}
            onChange={handleChange}
          />
          <SelectField
            label="Location"
            name="location"
            options={["0040 - 831 Bower Hill, California, USA"]}
            value={formData.location}
            onChange={handleChange}
          />
        </div>
        <p className="text-[#515151] mt-[20px] font-ubuntu font-normal text-[16px]">
          Input the individual who's record is being reviewed, if applicable
        </p>
      </section>

      <hr className="w-full border-0 border-t border-[#767676] my-[10px]" />

      <section className="mt-6">
        <h2 className="text-[#002c57] tracking-[0.4px] mt-0 font-[500] text-[14px] leading-[114%] font-ubuntu md:max-w-full">
          Additional Information
        </h2>
        <div className="flex mt-5 gap-5 justify-between md:flex-wrap">
          <SelectField
            label="Regulation Number - Desc"
            name="regulationNumberDesc"
            options={["Option 1", "Option 2"]}
            value={formData.regulationNumberDesc}
            onChange={handleChange}
          />
          <SelectField
            label="Corrective Action Type"
            name="correctiveActionType"
            options={["Type 1", "Type 2"]}
            value={formData.correctiveActionType}
            onChange={handleChange}
          />
          <SelectField
            label="Corrective Action Category"
            name="correctiveActionCategory"
            options={["Category 1", "Category 2"]}
            value={formData.correctiveActionCategory}
            onChange={handleChange}
          />
          <SelectField
            label="Corrective Action Due Date"
            name="correctiveActionDueDate"
            options={["Date 1", "Date 2"]}
            value={formData.correctiveActionDueDate}
            onChange={handleChange}
          />
        </div>
        <div className="flex mt-5 gap-5 justify-between md:flex-wrap">
          <FormField
            label="Responsible Party"
            name="responsibleParty"
            placeholder="Enter a name or email address"
            value={formData.responsibleParty}
            onChange={handleChange}
          />
          <SelectField
            label="Corrective Action Completion Date"
            name="correctiveActionCompletionDate"
            options={["Date 1", "Date 2"]}
            value={formData.correctiveActionCompletionDate}
            onChange={handleChange}
          />
        </div>
        <div className="flex mt-5 gap-5 justify-between md:flex-wrap">
          <TextAreaField
            label="Describe Citation/Violation of Regulation"
            name="describeCitation"
            placeholder="Type in"
            value={formData.describeCitation}
            onChange={handleChange}
          />
          <TextAreaField
            label="Corrective Action Required By Inspector"
            name="correctiveActionRequired"
            placeholder="Type in"
            value={formData.correctiveActionRequired}
            onChange={handleChange}
          />
        </div>
        <TextAreaField
          label="Comments/Follow-up Comments"
          name="comments"
          placeholder="Type in"
          value={formData.comments}
          onChange={handleChange}
        />
      </section>

      <section className="mt-6">
        <button
          className="font-[500] text-[20px] font-ubuntu rounded-full py-[10px] px-[18px] cursor-pointer sm:px-[20px] text-[#f16622] bg-white border border-[#f16622]"
          type="button"
        >
          Cancel
        </button>
        <button
          className="font-[500] text-[20px] font-ubuntu rounded-full py-[10px] px-[18px] cursor-pointer sm:px-[20px] text-white bg-[#f16622] border-none"
          onClick={handleSubmit}
        >
          Save
        </button>
        <button
          className="font-[500] text-[20px] font-ubuntu rounded-full py-[10px] px-[18px] cursor-pointer sm:px-[20px] flex items-center gap-[10px] text-[#f16622] bg-white border border-[#f16622]"
          type="button"
        >
          Provider Plan Of Action
          <img
            className="w-5 h-5"
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/11a66aa01c9ed3d3ab706b1c18a13560e6d5bb36a3cd8d5d4aeb69e78f7aa86b?apiKey=1607f544a0344092815fcb145a04f0bb&"
            alt=""
          />
        </button>
      </section>
    </form>
  );
};

export default Citation;
