import * as React from "react";
import styled from "styled-components";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

const UsersDropDownMenuItems = [
  {
    label: "View Profile",
    key: "1",
  },
  {
    label: "Log Out",
    key: "2",
  },
];

function Header() {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const { instance } = useMsal();
  const navigate = useNavigate();
  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const handleDropdownClick = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleLogout = async () => {
    console.log("Logging out...");
    localStorage.clear();
    sessionStorage.clear();

    await instance.logoutRedirect({
      postLogoutRedirectUri: window.location.origin,
    });

    console.log("Logged out, navigating to home...");
    navigate("/");
  };

  const userMenuItemClicked = (e) => {
    if (e.key === "1") {
      navigate("/profile");
    } else if (e.key === "2") {
      handleLogout();
    }
  };

  return (
    <header className="fixed top-0 z-10 bg-white flex gap-5 justify-between p-[19px_21px] w-full md:flex-wrap md:px-5">
      <div className="flex items-center w-[100%]  justify-between">
        <div className="flex gap-3 items-center">
          <img
            className="w-10 object-contain"
            src="https://birddata.blob.core.windows.net/b2clogin/cust-app/images/company-logo-1.png"
            alt=""
          />
          <img
            className="w-40 object-contain"
            src="https://birddata.blob.core.windows.net/b2clogin/cust-app/images/company-logo-2.png"
            alt="The BI Collaborative"
          />
        </div>
        <div className="flex items-center gap-5 text-sm text-[#515151] font-medium text-center">
          {!process.env
            .REACT_APP_DISABLE_NotificationAndEmailIcons_From_Header_Feature && (
            <>
              <img
                className="w-[14px] aspect-[0.88] object-contain fill-[#f16622]"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d847bd6dfb10b8894580f3d116255e05322968ac9903c8b628bb5332606c7c37?apiKey=1607f544a0344092815fcb145a04f0bb&"
                alt="Notification Icon"
              />
              <img
                className="w-[14px] aspect-[0.88] object-contain fill-[#f16622]"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9175f8a107ddab38d030742e24db309376627abf0d0cac4e10bea62b7ea56b1f?apiKey=1607f544a0344092815fcb145a04f0bb&"
                alt="Message Icon"
              />
            </>
          )}
          <Dropdown
            menu={{
              items: UsersDropDownMenuItems,
              onClick: userMenuItemClicked,
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <img
                  className="w-[20px] aspect-[0.88] object-contain fill-[#f16622]"
                  src="/images/avatar.webp"
                  alt="User Avatar"
                />
                {activeAccount ? activeAccount.name : "Loading..."}
                <DownOutlined style={{ color: "#f16622" }} />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
    </header>
  );
}




export default Header;
